<template>
    <div class="m3_contetns" style="margin-top: 50px">

    <!-- 회원가입 box 시작 { -->
    <div class="m_ipt_box">

        <!-- 회원가입 기본정보 시작 { -->
        <div class="mib_mod">
            <div class="w-100">
                <div class="x_div_center pb-3" style="border-bottom:1px solid #d9d9d9"><div class="h3 ff-kor1 fc-w6">회원가입하기</div></div>

                <!-- 회원유형 -->
                <div class="mib_box_a py-3">
                  <div class="mib_a1 text-end"><span class="fkr-set1">회원유형</span></div>
                  <div class="mib_a2 x_div_center"><div style="width:6px; height:6px; background-color:#e60; border-radius:50%"></div></div>
                  <div class="mib_a3">
                    <div class="m_type_set">
                      <div class="m_type_left x_div_center px-2">
                        <input type="radio" name="userType" id="userType_CU" v-model="usrType" value="CU"/>
                        <div>
                          <div><label for="userType_CU" :class="usrType == 'CU'? 'mbs m_in sec' : 'mbs m_in'" p_id="1"></label></div>
                          <div class="py-1"><span class="x_div_center fkr-set1">고객</span></div>
                        </div>
                        <div style="width:75px">

                        </div>
                      </div>
                      <div class="m_type_right x_div_center px-2">
                        <input type="radio" name="userType" id="userType_PA" v-model="usrType" value="PA"/>
                        <div>
                          <div><label for="userType_PA" :class="usrType == 'PA'? 'mbs m_pa sec' : 'mbs m_pa'" p_id="2"></label></div>
                          <div class="py-1"><span class="x_div_center fkr-set1">파트너기관</span></div>
                        </div>
                        <div style="width:110px; margin-top: -20px;">
                          <div class="ps-3">
                            <select class="custom-select ff-eng1 fs-085" :style="'height:36px; width:' + (isMobile? '80px' : '180px')" v-model="usrRoles" :disabled="usrType == '' || usrType == 'CU'">
                                <option v-for="(role, index) in roleOptionsPA" :key="index" :value="role.code">{{ role.name }}</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- 이메일 -->
                <div class="mib_box_a py-3">
                    <div class="mib_a1 text-end"><span class="fkr-set1">이메일</span></div>
                    <div class="mib_a2 x_div_center"><div style="width:6px; height:6px; background-color:#e60; border-radius:50%"></div></div>
                    <div class="mib_a3">

                        <div class="mib_a3s">
                            <div class="py-1"><input type="text" class="form-control ff-eng1" style="width:180px; height:36px" placeholder="E-mail" v-model="emailHead"></div>
                            <div class="x_div_center py-1 text-center" style="width:30px"><span class="ff-eng1 fs-09 fw-bold fc-w3">@</span></div>
                            <div class="py-1 pe-2"><input type="text" class="form-control ff-eng1" style="width:180px; height:36px" placeholder="" v-model="emailTail"></div>
                            <div class="mib_a3s_d py-1">
                                <select class="custom-select ff-eng1 fs-085" style="width:180px; height:36px" v-model="emailTail">
                                    <option value="">직접입력</option>
                                    <option value="naver.com">naver.com</option>
                                    <option value="gmail.com">gmail.com</option>
                                    <option value="daum.net">daum.net</option>
                                </select>
                            </div>
                        </div>

                    </div>
                </div>

                <!-- 이름 -->
                <div class="mib_box_a py-3">
                    <div class="mib_a1 text-end"><span class="fkr-set1">{{ usrType == 'PA' ? '법인명' : '이름' }}</span></div>
                    <div class="mib_a2 x_div_center"><div style="width:6px; height:6px; background-color:#e60; border-radius:50%"></div></div>
                    <div class="mib_a3"><input type="text" class="form-control fkr-set2" style="width:180px; height:36px" placeholder="이름을 입력하세요" v-model="fullName"></div>
                </div>

                <!-- 비밀번호 -->
                <div class="mib_box_b">
                    <div class="mib_set1 py-3">
                        <div class="mib_b1 text-end"><span class="fkr-set1">비밀번호</span></div>
                        <div class="mib_b2 x_div_center"><div style="width:6px; height:6px; background-color:#e60; border-radius:50%"></div></div>
                        <div class="mib_b3"><input type="password" class="form-control fkr-set2" style="width:180px; height:36px" placeholder="비밀번호를 입력하세요" v-model="psswrd"></div>
                    </div>
                    <div class="mib_set2 py-3">
                        <div class="mib_b1 text-end"><span class="fkr-set1">비밀번호 확인</span></div>
                        <div class="mib_b2 x_div_center"><div style="width:6px; height:6px; background-color:#e60; border-radius:50%"></div></div>
                        <div class="mib_b3"><input type="password" class="form-control fkr-set2" style="width:180px; height:36px" placeholder="비밀번호 확인" v-model="psswrdCnfrm"></div>
                    </div>
                </div>

                <!-- 휴대폰번호, 인증번호입력 -->
                <div class="mib_box_b">
                    <div class="mib_set1 py-3">
                        <div class="mib_b1 text-end"><span class="fkr-set1">휴대폰</span></div>
                        <div class="mib_b2 x_div_center"><div style="width:6px; height:6px; background-color:#e60; border-radius:50%"></div></div>
                        <div class="mib_b3">
                            <div class="x_div_center">
                                <div><input type="text" class="form-control fkr-set2" style="width:150px; height:36px" placeholder="'-' 없이 숫자만 입력" v-model="phone" :readonly="certPass"></div>
                                <div class="ps-1"><button class="fkr-set1 fc-w1" @click="callAuth()" style="width:75px; height:36px; border:0; background-color:#000; border-radius:4px;">인증요청</button></div>
                            </div>
                        </div>
                    </div>
                    <div class="mib_set2 py-3">
                        <div class="mib_b1 text-end"><span class="fkr-set1">인증번호</span></div>
                        <div class="mib_b2 x_div_center"><div style="width:6px; height:6px; background-color:#e60; border-radius:50%"></div></div>
                        <div class="mib_b3">
                            <div class="x_div_center">
                                <div><input type="text" class="form-control fkr-set2" style="width:150px; height:36px" placeholder="" v-model="certNmbr" :readonly="certPass"></div>
                                <div class="ps-1"><button class="fkr-set1 fc-w1" @click="confirmAuth()" style="width:75px; height:36px; border:0; background-color:#000; border-radius:4px;">확인</button></div>
                            </div>
                        </div>
                    </div>
                </div>
                <span v-if="buildMode !== 'production'" style="color: red; font-size: smaller; margin-left: 100px">개발서버에서는 번호인증이 필수가 아닙니다.</span>

                <!-- 영업파트너 -->
                <!-- <div class="mib_box_a py-3">
                    <div class="mib_a1 text-end"><span class="fkr-set1">영업파트너</span></div>
                    <div class="mib_a2 x_div_center"><div style="width:6px; height:6px; background-color:#e60; border-radius:50%"></div></div>
                    <div class="mib_a3">

                        <div class="mib_a3s">
                            <div class="py-1"><input type="text" class="form-control ff-eng1" style="width:180px; height:36px" placeholder="E-mail"></div>
                            <div class="x_div_center py-1 text-center" style="width:30px"><span class="ff-eng1 fs-09 fw-bold fc-w3">@</span></div>
                            <div class="py-1 pe-2"><input type="text" class="form-control ff-eng1" style="width:180px; height:36px" placeholder=""></div>
                            <div class="mib_a3s_d py-1">
                                <select class="custom-select ff-eng1 fs-085" style="width:180px; height:36px">
                                    <option value="">직접입력</option>
                                    <option value="naver.com">naver.com</option>
                                    <option value="google.com">google.com</option>
                                    <option value="daum.net">daum.net</option>
                                </select>
                            </div>
                        </div>

                    </div>
                </div> -->

                <!-- 파트너기관 추가정보 시작 { -->
                <div id="pid_2" class="pid_2" v-if="usrType == 'PA'">

                    <div class="x_div_center pt-5 pb-3" style="border-bottom:1px solid #d9d9d9"><div class="h3 ff-kor1 fc-w6">파트너기관 추가정보</div></div>

                    <!-- 사업자정보 -->
                    <div class="mib_box_b">
                        <div class="mib_set1 py-3">
                            <div class="mib_b1 text-end"><span class="fkr-set1">사업자등록번호</span></div>
                            <div class="mib_b2 x_div_center"><div style="width:6px; height:6px; background-color:#e60; border-radius:50%"></div></div>
                            <div class="mib_b3">
                                <div><input type="text" class="form-control fen-set1 text-center px-1" style="width:70px; height:36px" placeholder="" v-model="bsnssNmbrHead"></div>
                                <div class="x_div_center px-1">-</div>
                                <div><input type="text" class="form-control fen-set1 text-center px-1" style="width:50px; height:36px" placeholder="" v-model="bsnssNmbrMid"></div>
                                <div class="x_div_center px-1">-</div>
                                <div><input type="text" class="form-control fen-set1 text-center px-1" style="width:70px; height:36px" placeholder="" v-model="bsnssNmbrTail"></div>
                            </div>
                        </div>
                        <div class="mib_set2 py-3">
                            <div class="mib_b1 text-end"><span class="fkr-set1">사업자등록증</span></div>
                            <div class="mib_b2 x_div_center"><div style="width:6px; height:6px; background-color:#e60; border-radius:50%"></div></div>
                            <div class="mib_b3"><input type="file" class="form-control fkr-set2" style="width:100%; height:36px" @change="fileHandling($event, 0)"></div>
                        </div>
                    </div>

                    <!-- 주소 -->
					<div class="mib_box_a py-3">
						<div class="mib_a1 text-end"><span class="fkr-set1">주소</span></div>
						<div class="mib_a2 x_div_center"><div style="width:6px; height:6px; background-color:#e60; border-radius:50%"></div></div>
						<div class="mib_a3">

							<div class="d-flex">
								<div class="py-1"><input type="text" class="form-control ff-eng1" v-model="postcode" style="width:100px; height:36px" placeholder="" readonly></div>
								<div class="py-1 ps-1"><button class="fkr-set1 fc-w1" @click="searchAddr()" style="width:75px; height:36px; border:0; background-color:#000; border-radius:4px;">주소검색</button></div>
							</div>

							<div class="">
								<div class="py-1"><input type="text" class="form-control fkr-set2" v-model="addr1" style="width:100%; height:36px" placeholder="기본주소" readonly></div>
							</div>

							<div class="">
								<div class="py-1"><input type="text" class="form-control fkr-set2" v-model="addr2" style="width:100%; height:36px" placeholder="상세주소"></div>
							</div>

						</div>
					</div>

                    <!-- 지명원, 국가기술자격증 -->
                    <div class="mib_box_b">
                        <div class="mib_set1 py-3">
                            <div class="mib_b1 text-end"><span class="fkr-set1">지명원</span></div>
                            <div class="mib_b2 x_div_center"></div>
                            <div class="mib_b3"><input type="file" class="form-control fkr-set2" style="width:100%; height:36px" @change="fileHandling($event, 1)"></div>
                        </div>
                        <div class="mib_set2 py-3">
                            <div class="mib_b1 text-end"><span class="fkr-set1">국가자격증</span></div>
                            <div class="mib_b2 x_div_center"></div>
                            <div class="mib_b3"><input type="file" class="form-control fkr-set2" style="width:100%; height:36px" @change="fileHandling($event, 2)"></div>
                        </div>
                    </div>

                    <!-- 홈페이지 -->
                    <div class="mib_box_a py-3">
                        <div class="mib_a1 text-end"><span class="fkr-set1">홈페이지</span></div>
                        <div class="mib_a2 x_div_center"></div>
                        <div class="mib_a3"><input type="text" name="" value="" class="form-control fen-set1" style="width:100%; height:36px" placeholder="https://" v-model="hmpg"></div>
                    </div>

                    <!-- 포트폴리오 -->
                    <div class="mib_box_a py-3">
                        <div class="mib_a1 text-end"><span class="fkr-set1">포트폴리오</span></div>
                        <div class="mib_a2 x_div_center"></div>
                        <div class="mib_a3"><input type="text" name="" value="" class="form-control fen-set1" style="width:100%; height:36px" placeholder="https://" v-model="link"></div>
                    </div>

                    <div class="mib_box_c w-100 pt-3" style="padding-bottom: 10px"><div class="fkr-set1 fc-w6">수상이력</div></div>

                    <div class="mib_box_c">
                        <div class="x_div_center" style="height:36px; border-top-left-radius:.3rem; border-top-right-radius:.3rem; background:#000;">
                            <div class="x_div_center" style="width:20%"><span class="fkr-set2 fc-w1">수상명</span></div>
                            <div class="x_div_center" style="width:25%"><span class="fkr-set2 fc-w1">수상기관</span></div>
                            <div class="x_div_center" style="width:30%"><span class="fkr-set2 fc-w1">프로젝트</span></div>
                            <div class="x_div_center" style="width:20%"><span class="fkr-set2 fc-w1">수상연도</span></div>
                            <div class="x_div_center" style="width:5%"><span class="fkr-set2 fc-w1"></span></div>
                        </div>

                        <div class="x_div_center ls_bg1" style="height:55px" v-for="(award, index) in awardList" :key="index">
                            <div class="x_div_center px-1" style="width:20%"><input type="text" v-model="award.awardNm" class="form-control fkr-set2 fc-w5 text-center" style="width:100%; height:36px" placeholder="수상명"></div>
                            <div class="x_div_center px-1" style="width:25%"><input type="text" v-model="award.awardInst" class="form-control fkr-set2 fc-w5 text-center" style="width:100%; height:36px" placeholder="수상기관"></div>
                            <div class="x_div_center px-1" style="width:30%"><input type="text" v-model="award.awardPrj" class="form-control fkr-set2 fc-w5 text-center" style="width:100%; height:36px" placeholder="프로젝트"></div>
                            <div class="x_div_center px-1" style="width:20%"><input type="text" v-model="award.awardYear" class="form-control fkr-set2 fc-w5 text-center" style="width:100%; height:36px" placeholder="YYYY"></div>
                            <div class="x_div_center px-1" style="width:5%"><a href="javascript:void(0)" class="rm_frm" @click="removeAward(index)"><i class="fa-solid fa-circle-minus" style="color:#C00"></i></a></div>
                        </div>

                        <div id="v_frm"></div>

                        <div class="x_div_center py-2"><a href="javascript:void(0)" id="add_frm" @click="addAward()"><i class="fa-solid fa-circle-plus fs-15" style="color:#000"></i></a></div>

                    </div>
                </div>
                <!-- } 파트너기관 추가정보 끝 -->

                <!-- 약관동의 시작 { -->
                <div>
                    <div class="pt-4 pb-2">
                        <ul class="custom-control custom-checkbox">
                            <li class="chk_box"><input type="checkbox" id="secret" name="secret" class="custom-control-input" v-model="allCheck" value="true" >
                            <label class="custom-control-label" for="secret"><span class="fkr-set2">전체동의</span></label></li>
                            <li class="chk_box"><input type="checkbox" id="agree1" name="agree1" class="custom-control-input" v-model="checkTiu" value="true" >
                            <label class="custom-control-label" for="agree1"><span class="fkr-set2">이용약관에 동의합니다</span> &nbsp; <a class="fkr-set2 fc-w3" href="javascript:void(0)" @click="isShowTermsOfService = true" data-toggle="modal" data-id="1'">상세보기</a></label></li>
                            <li class="chk_box"><input type="checkbox" id="agree2" name="agree2" class="custom-control-input" v-model="checkPp" value="true" >
                            <label class="custom-control-label" for="agree2"><span class="fkr-set2">개인정보처리방침에 동의합니다</span> &nbsp; <a class="fkr-set2 fc-w3" href="javascript:void(0)" @click="isShowPrivecyPolicy = true" data-toggle="modal" data-id="1'">상세보기</a></label></li>
                            <div v-if="usrType == 'PA'">
                                <li class="chk_box"><input type="checkbox" id="agree3" name="agree3" class="custom-control-input" v-model="checkNoti" value="true" >
                                <label class="custom-control-label" for="agree3"><span class="fkr-set2">수주 가능한 프로젝트의 입찰 모집 알림 수신에 동의합니다(선택)</span></label></li>
                            </div>
                            <div v-else>
                                <li class="chk_box"><input type="checkbox" id="agree3" name="agree3" class="custom-control-input" v-model="checkNoti" value="true" >
                                <label class="custom-control-label" for="agree3"><span class="fkr-set2">프로젝트의 작업안내보고 알림 수신에 동의합니다(선택)</span></label></li>
                            </div>
                        </ul>
                    </div>
                </div>

                <!-- } 약관동의 끝 -->

                <div class="x_div_center" style="margin-top: 15px">
                    <div class="px-2"><button class="fkr-set1" @click="submit()" :disabled="submitting" style="width:150px; height:37px; border:0; background-color:#000; border-radius:4px; color:#fff">회원가입하기<i v-if="submitting" style="margin-left: 10px" class='fa fa-spinner fa-spin'></i></button></div>
                </div>

            </div>
        </div>
        <!-- } 회원가입 기본정보 끝 -->

    </div>
    <!-- } 회원가입 box 끝 -->
    <terms-of-service v-if="isShowTermsOfService" @close="isShowTermsOfService = false"/>
    <privacy-policy v-if="isShowPrivecyPolicy" @close="isShowPrivecyPolicy = false"/>
    </div>
</template>

<script>
import TermsOfService from '@/components/TermsOfService.vue';
import PrivacyPolicy from '@/components/PrivacyPolicy.vue';
import Auth from '@/api/auth'
import Api from '@/api'
export default {
    name: 'SignIn',
    components: { PrivacyPolicy, TermsOfService },
    props: {
        isMobile: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            emailHead: '',
            emailTail: '',
            email: '',
            fullName: '',
            psswrd: '',
            psswrdCnfrm: '',
            phone: '',
            certNmbr: '',
            usrCertId: '',
            certPass: false,
            usrType: '',
            usrRoles: '',
            roleOptionsPA : [
                {code: '', name: '유형선택'},
                {code: 'DS', name: '건축설계사/감리'},
                {code: 'EN', name: '엔지니어링사'},
                {code: 'MF', name: '모듈러제작사'},
                {code: 'IT', name: 'IT 및 사업파트너'},
                {code: 'DH', name: '해체사'},
                {code: 'CT', name: '종합건설사'},
                {code: 'ST', name: '전문건설사'},
                {code: 'IN', name: '인테리어사'},
                {code: 'MT', name: '자재사'},
                {code: 'CV', name: '측량/토목'},
                {code: 'DE', name: '운송 및 중장비'},
                {code: 'SL', name: '영업파트너'},
                {code: 'RC', name: '공공 및 학협회'},
                {code: 'OT', name: '기타'}
            ],
            
            daumObj: null,
            bsnssNmbrHead: '',
            bsnssNmbrMid: '',
            bsnssNmbrTail: '',
            bsnssNmbr: '',
            file: [],
            postcode: '',
            addr1: '',
            addr2: '',
            hmpg: '',
            link: '',
            awardList: [],
            allCheck: false,
            checkTiu: false,
            checkPp: false,
            checkNoti: false,
            buildMode: process.env.NODE_ENV,
            m3phone: process.env.VUE_APP_M3_PHONE,
            submitting: false,
            isShowTermsOfService: false,
            isShowPrivecyPolicy: false,
        }
    },
    async mounted() {
        if(await Auth.isLogedIn()){
           this.$router.push('/');
        }

        this.daumObj = new window.daum.Postcode({
            oncomplete: (data) => {
            var addr = ''; // 주소 변수
            var extraAddr = ''; // 참고항목 변수

            //사용자가 선택한 주소 타입에 따라 해당 주소 값을 가져온다.
            if (data.userSelectedType === 'R') { // 사용자가 도로명 주소를 선택했을 경우
                addr = data.roadAddress;
            } else { // 사용자가 지번 주소를 선택했을 경우(J)
                addr = data.jibunAddress;
            }

            // 사용자가 선택한 주소가 도로명 타입일때 참고항목을 조합한다.
            if(data.userSelectedType === 'R'){
                // 법정동명이 있을 경우 추가한다. (법정리는 제외)
                // 법정동의 경우 마지막 문자가 "동/로/가"로 끝난다.
                if(data.bname !== '' && /[동|로|가]$/g.test(data.bname)){
                    extraAddr += data.bname;
                }
                // 건물명이 있고, 공동주택일 경우 추가한다.
                if(data.buildingName !== '' && data.apartment === 'Y'){
                    extraAddr += (extraAddr !== '' ? ', ' + data.buildingName : data.buildingName);
                }
                // 표시할 참고항목이 있을 경우, 괄호까지 추가한 최종 문자열을 만든다.
                if(extraAddr !== ''){
                    extraAddr = ' (' + extraAddr + ')';
                }
            }
            this.postcode = data.zonecode;
            this.addr1 = addr + extraAddr;
            }
        });
    },
    watch: {
        phone(val) {
            this.phone = val.replace(/-/g, '').replace(/\s/g, '');
        },
        usrType(val) {
            if(val == 'CU') {
                this.usrRoles = 'OW';
            }
            else {
                this.usrRoles = '';
            }
        },
        allCheck(val) {
            if(val) {
                this.checkTiu = true;
                this.checkPp = true;
                this.checkNoti = true;
            }
            else {
                this.checkTiu = false;
                this.checkPp = false;
                this.checkNoti = false;
            }
        }
    },
    methods: {
        async callAuth() {
            if(!this.phone) {
                alert('휴대폰번호를 입력해주세요!');
            }
            else {
                var phoneRegular = this.phone.replace(/^0/g, '82');
                var rtnval = await Api.post('/api/v1/user/count/phone', {phone: this.phone});
                if(rtnval.result){
                    if(this.phone != this.m3phone && rtnval.data.count > 0) {
                        alert('이미 등록된 휴대폰번호입니다.');
                        return false;
                    }

                    var result = await Api.post('/api/v1/common/send/sms', {phone: phoneRegular});
                    if(result.result){
                        this.usrCertId = result.data.usrCertId;
                        alert('입력하신 번호로 인증번호가 전송되었습니다.\n인증번호를 입력하고 확인 버튼을 눌러주세요.')
                    }
                    else{
                        alert(result.errormsg);
                    }
                }
                else{
                    alert(rtnval.errormsg);
                }
            }
        },
        async confirmAuth() {
            var result = await Api.post('/api/v1/common/check/certNmbr', {certNmbr: this.certNmbr, usrCertId: this.usrCertId});
            if(result.result){
                this.certPass = true;
                alert('휴대폰번호가 정상적으로 인증되었습니다.')
            }
            else{
                alert(result.errormsg);
            }
        },
        fileHandling(e, index) {
            this.file[index] = e.target.files[0];
        },
        searchAddr() {
          this.daumObj.open();
        },
        addAward() {
            this.awardList.push({awardNm: '', awardInst: '', awardPrj: '', awardYear: ''})
        },
        removeAward(index) {
            this.awardList = this.awardList.filter((v,i) => i != index );
        },
        async submit() {
            if(this.emailHead && this.emailTail){
                this.email = this.emailHead + '@' + this.emailTail;
            }
            this.bsnssNmbr = this.bsnssNmbrHead + this.bsnssNmbrMid + this.bsnssNmbrTail;
            if(this.validation()){
                this.submitting = true;
                var request = {
                    addr1: this.addr1,
                    addr2: this.addr2,
                    postCd: this.postcode,
                    awardList: this.awardList,
                    email: this.email,
                    fullName: this.fullName,
                    hmpg: this.hmpg,
                    link: this.link,
                    oauthType: "NONE",
                    phone: this.phone,
                    psswrd: this.psswrd,
                    usrRoles: this.usrRoles,
                    usrType: this.usrType,
                    bsnssNmbr: this.bsnssNmbr,
                    bsnssOrgNm: this.file[0]? this.file[0].name : '',
                    profOrgNm: this.file[1]? this.file[1].name : '',
                    ntnlOrgNm: this.file[2]? this.file[2].name : '',
                    newBddngNotiRcvYn: this.checkNoti? "Y" : "N"
                }
                const formData = new FormData();
                formData.append(
                    "request",
                    new Blob([JSON.stringify(request)], { type: "application/json" })
                );
                formData.append("bsnssImg", this.file[0]);
                formData.append("profImg", this.file[1]);
                formData.append("ntnlImg", this.file[2]);
                var result = await Api.post('/api/v1/user/create', formData);
                if(result.result){
                    alert('회원가입을 축하합니다!')
                    if(this.$route.query.fromSuggestion){
                        this.$router.push({path: '/member/login', query: {fromSuggestion: 1}});
                    }
                    else {
                        this.$router.push('/');
                    }
                }
                else{
                    this.submitting = false;
                    alert(result.errormsg);
                }
            }
        },
        validation() {
            if(!this.email) {
                alert('이메일은 필수 입력사항입니다.');
                return false;
            }
            if(!this.fullName) {
                alert('이름은 필수 입력사항입니다.');
                return false;
            }
            if(!this.psswrd) {
                alert('비밀번호는 필수 입력사항입니다.');
                return false;
            }
            if(!this.phone) {
                alert('휴대폰번호는 필수 입력사항입니다.');
                return false;
            }
            if(!this.usrType) {
                alert('회원유형은 필수 선택사항입니다.');
                return false;
            }
            if(this.psswrd != this.psswrdCnfrm) {
                alert('확인 비밀번호가 일치하지 않습니다.')
                return false;
            }
            if(this.usrType == 'PA') {
                if(!this.usrRoles) {
                    alert('파트너기관 유형은 필수 선택사항입니다.');
                    return false;
                }
                if(!(this.bsnssNmbrHead && this.bsnssNmbrMid && this.bsnssNmbrTail)) {
                    alert('사업자등록번호는 필수 입력사항입니다.');
                    return false;
                }
                if(!this.file[0]) {
                    alert('사업자등록증은 필수 첨부파일입니다.');
                    return false;
                }
                if(!this.postcode) {
                    alert('주소는 필수 입력사항입니다.');
                    return false;
                }
                if((this.hmpg && this.hmpg.search(/^http/g) < 0) || (this.link && this.link.search(/^http/g))) {
                    alert('사이트 주소는 http:// 또는 https:// 로 시작해야합니다.');
                    return false;
                }
            }
            if(!this.checkTiu || !this.checkPp) {
                alert('이용약관 및 개인정보처리방침은 필수 동의사항입니다.');
                return false;
            }
            if(this.buildMode === 'production' && !this.certPass) {
                alert('휴대폰인증은 필수사항입니다.');
                return false;
            }

            return true;
        }
    }
}
</script>

<style scoped>
.custom-radio .custom-control-label::before, .custom-control-label::after {
    top: 0.2rem;
}

</style>