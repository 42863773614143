import UsrMng from '@/views/admin/usr/UsrMng.vue'
import UsrDtl from '@/views/admin/usr/UsrDtl.vue'
import UsrSttc from '@/views/admin/usr/UsrSttc.vue'
import PrjMng from '@/views/admin/prj/PrjMng.vue'
import PrimPrjMng from '@/views/admin/prj/PrimPrjMng.vue'
import BsnssPrtnrMng from "@/views/admin/prtnr/BsnssPrtnrMng";
import BddngPrcssMng from "@/views/admin/bddng/BddngPrcssMng";
import BddngListMng from "@/views/admin/bddng/BddngListMng";
import PrjBddngPrcssMng from "@/views/admin/bddng/PrjBddngPrcssMng";
import BddngSettingMng from "@/views/admin/bddng/BddngSettingMng";
import WrttnCntrctMng from "@/views/admin/wrttn/WrttnCntrctMng";
import CntrctExpnsMng from "@/views/admin/expns/CntrctExpnsMng";
import DsgnMng from "@/views/admin/dsgn/DsgnMng";
import MnfctMng from "@/views/admin/mnfct/MnfctMng";
import CntrctMng from "@/views/admin/cntrct/CntrctMng";
import IntrrMng from "@/views/admin/intrr/IntrrMng";
import PrjPymntPrcssMng from "@/views/admin/pymnt/PrjPymntPrcssMng";
import AdminMng from "@/views/admin/dsgnt/AdminMng";
import CnslrMng from "@/views/admin/dsgnt/CnslrMng";
import BdgtrMng from "@/views/admin/dsgnt/BdgtrMng";
import UsdMtrlList from "@/views/admin/usdmtrl/UsdMtrlList";
import UnitPriceList from "@/views/admin/estmt/UnitPriceList";
import UnitPriceEdit from "@/views/admin/estmt/UnitPriceEdit";
import EstimationList from "@/views/admin/estmt/EstimationList";
import EstimationEdit from "@/views/admin/estmt/EstimationEdit";
import FaqEdit from "@/views/admin/site_mgmnt/faq/FaqEdit"
import FaqList from "@/views/admin/site_mgmnt/faq/FaqList"
import TextTemplateEdit from "@/views/admin/site_mgmnt/panda/TextTemplateEdit"
import TextTemplateList from "@/views/admin/site_mgmnt/panda/TextTemplateList"
import PartnershipEdit from "@/views/admin/site_mgmnt/partnership/PartnershipEdit"
import PartnershipList from "@/views/admin/site_mgmnt/partnership/PartnershipList"
import PartnershipSetCtgry from "@/views/admin/site_mgmnt/partnership/PartnershipSetCtgry"
import QuestionFormList from "@/views/admin/site_mgmnt/question/QuestionFormList"
import QuestionFormEdit from "@/views/admin/site_mgmnt/question/QuestionFormEdit"
import QuestionSetCtgry from "@/views/admin/site_mgmnt/question/QuestionSetCtgry"
import RecentEdit from "@/views/admin/site_mgmnt/recent/RecentEdit"
import RecentList from "@/views/admin/site_mgmnt/recent/RecentList"
import MemberEdit from "@/views/admin/site_mgmnt/member/MemberEdit"
import MemberList from "@/views/admin/site_mgmnt/member/MemberList"
import CompanyInfo from "@/views/admin/site_mgmnt/companyinfo/CompanyInfo"
import CertificateList from "@/views/admin/site_mgmnt/certificates/CertificateList"
import DownloadItemList from "@/views/admin/site_mgmnt/download/DownloadItemList"
import NewsList from "@/views/admin/site_mgmnt/news/NewsList"
import PartyList from "@/views/admin/site_mgmnt/sicksha/PartyList"
import PartyEnlist from "@/views/admin/site_mgmnt/sicksha/PartyEnlist"
import SmtpHistory from "@/views/admin/site_mgmnt/smtp_history/SmtpHistory"
import CodeList from "@/views/admin/site_mgmnt/code/CodeList"
import AuthList from "@/views/admin/site_mgmnt/usr_authority/AuthList"
import AdminRoleList from "@/views/admin/site_mgmnt/adminrole/AdminRoleList"
import EventResult from '@/views/admin/site_mgmnt/event/EventResult.vue'
import EventKeywordList from '@/views/admin/site_mgmnt/schedule_mgmnt/EventKeywordList.vue'

export default [

    /* routing table 작성원칙
        - path: 1번째 segment => 'admin'(고정) / 2번째 segment => 메뉴 카테고리 분류코드
        - name: 해당 페이지 import명(component명)과 동일
        - component: name 항목과 동일

        - menuName: LNB에서 보여질 메뉴 카테고리명
        - orderInLnb: LNB에서 보여질 메뉴 카테고리의 정렬순서
        - pageName: LNB에서 보여질 메뉴 카테고리 내의 해당 페이지명
        - orderInMenu: LNB에서 보여질 메뉴 카테고리 내의 해당 페이지 정렬순서
        - lnbVisiability: LNB에서 해당 페이지 노출여부

        // 동일 카테고리에 속한 페이지의 객체는 path의 2번째 segment, menuName, orderInLnb 속성이 서로 동일해야함
    */

    // 1. 회원 관리 
    {
        path: '/admin/usr/usr-mng',
        name: 'UsrMng',
        component: UsrMng,

        menuName: '회원 관리',
        orderInLnb: 1,
        pageName: '회원정보 조회',
        orderInMenu: 1,
        lnbVisiability: true
    },
    {
        path: '/admin/usr/usr-mng/:usrId',
        name: 'UsrDtl',
        component: UsrDtl,
        
        menuName: '회원 관리',
        orderInLnb: 1,
        pageName: '회원정보 상세',
        orderInMenu: -1,
        lnbVisiability: false
    },
    {
        path: '/admin/usr/usr-sttc',
        name: 'UsrSttc',
        component: UsrSttc,

        menuName: '회원 관리',
        orderInLnb: 1,
        pageName: '회원가입 통계',
        orderInMenu: 2,
        lnbVisiability: true
    },
    {
        path: '/admin/usr/bsnss-prtnr-mng',
        name: 'BsnssPrtnrMng',
        component: BsnssPrtnrMng,

        menuName: '회원 관리',
        orderInLnb: 1,
        pageName: '사업파트너 관리',
        orderInMenu: 3,
        lnbVisiability: true
    },
    {
        path: '/admin/usr/admin-mng',
        name: 'AdminMng',
        component: AdminMng,

        menuName: '회원 관리',
        orderInLnb: 1,
        pageName: '관리자 지정',
        orderInMenu: 4,
        lnbVisiability: true
    },
    {
        path: '/admin/usr/cnslr-mng',
        name: 'CnslrMng',
        component: CnslrMng,

        menuName: '회원 관리',
        orderInLnb: 1,
        pageName: '상담자 지정',
        orderInMenu: 5,
        lnbVisiability: true
    },
    {
        path: '/admin/usr/bdgtr-mng',
        name: 'BdgtrMng',
        component: BdgtrMng,

        menuName: '회원 관리',
        orderInLnb: 1,
        pageName: '예산집행인 지정',
        orderInMenu: 6,
        lnbVisiability: true
    },

    // 2. 매칭 관리
    {
        path: '/admin/match/dsgn-mng',
        name: 'DsgnMng',
        component: DsgnMng,

        menuName: '매칭 관리',
        orderInLnb: 2,
        pageName: '설계',
        orderInMenu: 1,
        lnbVisiability: true
    },
    {
        path: '/admin/match/mnfct-mng',
        name: 'MnfctMng',
        component: MnfctMng,

        menuName: '매칭 관리',
        orderInLnb: 2,
        pageName: '제조',
        orderInMenu: 2,
        lnbVisiability: true
    },
    {
        path: '/admin/match/cntrct-mng',
        name: 'CntrctMng',
        component: CntrctMng,

        menuName: '매칭 관리',
        orderInLnb: 2,
        pageName: '시공',
        orderInMenu: 3,
        lnbVisiability: true
    },
    {
        path: '/admin/match/intrr-mng',
        name: 'IntrrMng',
        component: IntrrMng,

        menuName: '매칭 관리',
        orderInLnb: 2,
        pageName: '인테리어',
        orderInMenu: 4,
        lnbVisiability: true
    },

    // 3. 비딩 관리
    {
        path: '/admin/bddng/bddng-setting-mng',
        name: 'BddngSettingMng',
        component: BddngSettingMng,

        menuName: '비딩 관리',
        orderInLnb: 3,
        pageName: '비딩관련 설정',
        orderInMenu: 1,
        lnbVisiability: true
    },
    {
        path: '/admin/bddng/bddng-list-mng',
        name: 'BddngListMng',
        component: BddngListMng,

        menuName: '비딩 관리',
        orderInLnb: 3,
        pageName: '비딩 목록 조회',
        orderInMenu: 2,
        lnbVisiability: true
    },
    {
        path: '/admin/bddng/bddng-prcss-mng',
        name: 'BddngPrcssMng',
        component: BddngPrcssMng,

        menuName: '비딩 관리',
        orderInLnb: 3,
        pageName: '프로젝트별 비딩 관리',
        orderInMenu: 3,
        lnbVisiability: true
    },
    {
        path: '/admin/bddng/prj-bddng-prcss-mng',
        name: 'PrjBddngPrcssMng',
        component: PrjBddngPrcssMng,

        menuName: '비딩 관리',
        orderInLnb: 3,
        pageName: '비딩 진행지연 대시보드',
        orderInMenu: 4,
        lnbVisiability: true
    },

    // 4. 계약 관리
    {
        path: '/admin/cntrct/wrttn-cntrct-mng',
        name: 'WrttnCntrctMng',
        component: WrttnCntrctMng,

        menuName: '계약 관리',
        orderInLnb: 4,
        pageName: '계약서 관리',
        orderInMenu: 1,
        lnbVisiability: true
    },
    {
        path: '/admin/cntrct/cntrct-expns-mng',
        name: 'CntrctExpnsMng',
        component: CntrctExpnsMng,

        menuName: '계약 관리',
        orderInLnb: 4,
        pageName: '계약금 관리',
        orderInMenu: 2,
        lnbVisiability: true
    },
    {
        path: '/admin/cntrct/prj-pymnt-prcss-mng',
        name: 'PrjPymntPrcssMng',
        component: PrjPymntPrcssMng,

        menuName: '계약 관리',
        orderInLnb: 4,
        pageName: '대금 집행지연 대시보드',
        orderInMenu: 3,
        lnbVisiability: true
    },
    
    // 5. 프로젝트 관리
    {
        path: '/admin/prj/prj-mng',
        name: 'PrjMng',
        component: PrjMng,

        menuName: '프로젝트 관리',
        orderInLnb: 5,
        pageName: '프로젝트 조회',
        orderInMenu: 1,
        lnbVisiability: true
    },
    {
        path: '/admin/prj/primprj-mng',
        name: 'PrimPrjMng',
        component: PrimPrjMng,

        menuName: '프로젝트 관리',
        orderInLnb: 5,
        pageName: '후보프로젝트 조회',
        orderInMenu: 2,
        lnbVisiability: true
    },

    // 6. 중고모듈거래 관리
    {
        path: '/admin/usdmtrl/list',
        name: 'UsdMtrlList',
        component: UsdMtrlList,

        menuName: '중고모듈거래 관리',
        orderInLnb: 6,
        pageName: '중고모듈거래 관리',
        orderInMenu: 1,
        lnbVisiability: true
    },

    // 7. 실시간 견적산출
    {
        path: '/admin/estmt/unit-price',
        name: 'UnitPriceList',
        component: UnitPriceList,

        menuName: '실시간 견적산출',
        orderInLnb: 7,
        pageName: '자재정보 관리',
        orderInMenu: 1,
        lnbVisiability: true
    },
    {
        path: '/admin/estmt/unit-price/:stdMtrlId',
        name: 'UnitPriceEdit',
        component: UnitPriceEdit,

        menuName: '실시간 견적산출',
        orderInLnb: 7,
        pageName: '자재정보 편집',
        orderInMenu: -1,
        lnbVisiability: false
    },
    {
        path: '/admin/estmt/list',
        name: 'EstimationList',
        component: EstimationList,

        menuName: '실시간 견적산출',
        orderInLnb: 7,
        pageName: '견적내역 관리',
        orderInMenu: 2,
        lnbVisiability: true
    },
    {
        path: '/admin/estmt/dtl/:estmtId',
        name: 'EstimationEdit',
        component: EstimationEdit,

        menuName: '실시간 견적산출',
        orderInLnb: 7,
        pageName: '견적내역 편집',
        orderInMenu: -2,
        lnbVisiability: false
    },

    // 8. 사이트 관리
    {
        path: '/admin/site/company-info',
        name: 'CompanyInfo',
        component: CompanyInfo,

        menuName: '사이트 관리',
        orderInLnb: 8,
        pageName: '회사정보',
        orderInMenu: 1,
        lnbVisiability: true
    },
    {
        path: '/admin/site/recent-list',
        name: 'RecentList',
        component: RecentList,

        menuName: '사이트 관리',
        orderInLnb: 8,
        pageName: '사업현황',
        orderInMenu: 2,
        lnbVisiability: true
    },
    {
        path: '/admin/site/recent-edit/:recentId',
        name: 'RecentEdit',
        component: RecentEdit,

        menuName: '사이트 관리',
        orderInLnb: 8,
        pageName: '프로젝트 편집',
        orderInMenu: -2,
        lnbVisiability: false
    },
    {
        path: '/admin/site/news-list',
        name: 'NewsList',
        component: NewsList,

        menuName: '사이트 관리',
        orderInLnb: 8,
        pageName: '뉴스',
        orderInMenu: 3,
        lnbVisiability: true
    },    
    {
        path: '/admin/site/faq-list',
        name: 'FaqList',
        component: FaqList,

        menuName: '사이트 관리',
        orderInLnb: 8,
        pageName: 'FAQ',
        orderInMenu: 4,
        lnbVisiability: true
    },    
    {
        path: '/admin/site/faq-edit/:faqId',
        name: 'FaqEdit',
        component: FaqEdit,

        menuName: '사이트 관리',
        orderInLnb: 8,
        pageName: 'FAQ 편집',
        orderInMenu: -4,
        lnbVisiability: false
    },
    {
        path: '/admin/site/partnership-list',
        name: 'PartnershipList',
        component: PartnershipList,

        menuName: '사이트 관리',
        orderInLnb: 8,
        pageName: '파트너기관',
        orderInMenu: 5,
        lnbVisiability: true
    },
    {
        path: '/admin/site/partnership-edit/:partnerId',
        name: 'PartnershipEdit',
        component: PartnershipEdit,

        menuName: '사이트 관리',
        orderInLnb: 8,
        pageName: '파트너기관 편집',
        orderInMenu: -5,
        lnbVisiability: false
    },
    {
        path: '/admin/site/partnership-setcategory',
        name: 'PartnershipSetCtgry',
        component: PartnershipSetCtgry,

        menuName: '사이트 관리',
        orderInLnb: 8,
        pageName: '파트너기관 심사당담자 설정',
        orderInMenu: -5,
        lnbVisiability: false
    },
    {
        path: '/admin/site/member-list',
        name: 'MemberList',
        component: MemberList,

        menuName: '사이트 관리',
        orderInLnb: 8,
        pageName: '임원진 프로필',
        orderInMenu: 6,
        lnbVisiability: true
    },
    {
        path: '/admin/site/member-edit/:memberId',
        name: 'MemberEdit',
        component: MemberEdit,

        menuName: '사이트 관리',
        orderInLnb: 8,
        pageName: '임원진 프로필 편집',
        orderInMenu: -6,
        lnbVisiability: false
    },
    {
        path: '/admin/site/certificate-list',
        name: 'CertificateList',
        component: CertificateList,

        menuName: '사이트 관리',
        orderInLnb: 8,
        pageName: '인증서 및 특허',
        orderInMenu: 7,
        lnbVisiability: true
    },
    {
        path: '/admin/site/download-list',
        name: 'DownloadItemList',
        component: DownloadItemList,

        menuName: '사이트 관리',
        orderInLnb: 8,
        pageName: '다운로드 게시판',
        orderInMenu: 8,
        lnbVisiability: true
    },
    {
        path: '/admin/site/questionform-list',
        name: 'QuestionFormList',
        component: QuestionFormList,

        menuName: '사이트 관리',
        orderInLnb: 8,
        pageName: '고객문의양식 설정',
        orderInMenu: 9,
        lnbVisiability: true
    },
    {
        path: '/admin/site/questionform-edit/:formId',
        name: 'QuestionFormEdit',
        component: QuestionFormEdit,

        menuName: '사이트 관리',
        orderInLnb: 8,
        pageName: '고객문의양식 편집',
        orderInMenu: -9,
        lnbVisiability: false
    },
    {
        path: '/admin/site/question-setcategory',
        name: 'QuestionSetCtgry',
        component: QuestionSetCtgry,

        menuName: '사이트 관리',
        orderInLnb: 8,
        pageName: '고객문의 담당자 설정',
        orderInMenu: -9,
        lnbVisiability: false
    },
    {
        path: '/admin/site/template-list',
        name: 'TextTemplateList',
        component: TextTemplateList,

        menuName: '사이트 관리',
        orderInLnb: 8,
        pageName: 'PANDA 자동응답문자 양식 목록',
        orderInMenu: -9,
        lnbVisiability: false
    },
    {
        path: '/admin/site/template-edit/:templateId',
        name: 'TextTemplateEdit',
        component: TextTemplateEdit,

        menuName: '사이트 관리',
        orderInLnb: 8,
        pageName: 'PANDA 자동응답문자 양식 편집',
        orderInMenu: -9,
        lnbVisiability: false
    },
    {
        path: '/admin/site/event-result',
        name: 'EventResult',
        component: EventResult,

        menuName: '사이트 관리',
        orderInLnb: 8,
        pageName: '프로모션 이벤트',
        orderInMenu: -10,
        lnbVisiability: false
    },

    // 9. 시스템 관리
    {
        path: '/admin/system/schedule/keyword',
        name: 'EventKeywordList',
        component: EventKeywordList,

        menuName: '시스템 관리',
        orderInLnb: 9,
        pageName: '출타현황 키워드',
        orderInMenu: 1,
        lnbVisiability: true
    },
    {
        path: '/admin/system/smtp/history',
        name: 'SmtpHistory',
        component: SmtpHistory,

        menuName: '시스템 관리',
        orderInLnb: 9,
        pageName: '고객문의 보관함',
        orderInMenu: 2,
        lnbVisiability: true
    },
    {
        path: '/admin/system/adminrole-list',
        name: 'AdminRoleList',
        component: AdminRoleList,

        menuName: '시스템 관리',
        orderInLnb: 9,
        pageName: '관리자 접근권한 설정',
        orderInMenu: 3,
        lnbVisiability: true
    },
    {
        path: '/admin/system/auth-list',
        name: 'AuthList',
        component: AuthList,

        menuName: '시스템 관리',
        orderInLnb: 9,
        pageName: '빌드마켓 사용자 접근권한 설정',
        orderInMenu: 4,
        lnbVisiability: true
    },
    {
        path: '/admin/system/code-list',
        name: 'CodeList',
        component: CodeList,

        menuName: '시스템 관리',
        orderInLnb: 9,
        pageName: '공통코드 관리',
        orderInMenu: 5,
        lnbVisiability: true
    },
    {
        path: '/admin/system/party-list',
        name: 'PartyList',
        component: PartyList,

        menuName: '시스템 관리',
        orderInLnb: 9,
        pageName: '식사당번그룹 목록',
        orderInMenu: -1,
        lnbVisiability: false
    },
    {
        path: '/admin/system/party-enlist/:partyId',
        name: 'PartyEnlist',
        component: PartyEnlist,

        menuName: '시스템 관리',
        orderInLnb: 9,
        pageName: '식사당번그룹 편집',
        orderInMenu: -1,
        lnbVisiability: false
    },
]