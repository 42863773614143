<template>
    <div>
        <div v-if="!isMobile" class="inquiry-page">
            <div class="bg">
                <div class="overlap">
                    <img class="purchase" src="@/assets/img_legacy/s_info/s_title_back.jpg" style="object-fit: cover;"/>
                    <div class="purchase-2"></div>
                    <div class="group">
                        <div class="text-wrapper">M3 다운로드 자료실</div>
                        <div class="text-wrapper-2"></div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="main-title">
            <div class="text-wrapper">M3 다운로드 자료실</div>
            <div class="text-wrapper-2"></div>
        </div>

        <div class="x_div_center">
            <div class="rp_set" style="border:0px solid red">

                <!-- M3 다운로드 자료실 -->
                <div class="py-3">

                    <div class="iw-1">
                        <div class="iw-3 x_div_center" style="align-items:start;">
                            <div class="iw-3 px-1 py-2">
                                <div class="p-1" style="border:1px solid #e1e1e1"><a href="/files/M3_User_Manual_(for_customers)_250307.pdf" target="_blank"><img src="@/assets/img_legacy/s_info/M3_User_Manual_(for_customers)_240318.jpg" width="100%"></a></div>
                            </div>
                            <div class="iw-3 px-1 py-2">
                                <div class="p-1" style="border:1px solid #e1e1e1"><a href="/files/M3_User_Manual_(for_designers)_250103.pdf" target="_blank"><img src="@/assets/img_legacy/s_info/M3_User_Manual_(for_designers)_240318.jpg" width="100%"></a></div>
                            </div>
                        </div>
                        <div class="iw-3 x_div_center" style="align-items:start;">
                            <div class="iw-3 px-1 py-2">
                                <div class="p-1" style="border:1px solid #e1e1e1"><a href="/files/M3_User_Manual_(for_manufacturers)_250103.pdf" target="_blank"><img src="@/assets/img_legacy/s_info/M3_User_Manual_(for_manufacturers)_240318.jpg" width="100%"></a></div>
                            </div>
                            <div class="iw-3 px-1 py-2">
                                <div class="p-1" style="border:1px solid #e1e1e1"><a href="/files/M3_User_Manual_(for_constructors)_250103.pdf" target="_blank"><img src="@/assets/img_legacy/s_info/M3_User_Manual_(for_constructors)_240318.jpg" width="100%"></a></div>
                            </div>
                        </div>
                    </div>

                    <div class="iw-1">
                        <div class="iw-3 x_div_center" style="align-items:start;">
                            <div class="iw-3 px-1 py-2">
                                <div class="p-1" style="border:1px solid #e1e1e1"><a href="/files/M3_User_Manual_(for_interiors)_250103.pdf" target="_blank"><img src="@/assets/img_legacy/s_info/M3_User_Manual_(for_interiors)_240318.jpg" width="100%"></a></div>
                            </div>
                            <div class="iw-3 px-1 py-2">
                            </div>
                        </div>
                        <div class="iw-3 x_div_center" style="align-items:start;">
                            <div class="iw-3 px-1 py-2">
                            </div>
                            <div class="iw-3 px-1 py-2">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="p-2"><span class="fkr-set2 fs-12 fc-w0"><i class="fa fa-download"/>&nbsp;&nbsp;<a href="http://www.inno-lab.co.kr/dfma/" style="color:#4e0000" target="_blank"><b>{{ lang == 'ko'? '모듈편집SW 다운로드' : 'Module Editing SW Download'}}</b></a></span></div>
                <MenuFooter v-if="lang == 'ko'"/>
                <MenuFooter_En v-else-if="lang == 'en'"/>
            </div>
        </div>
    </div>
</template>

<script>
import MenuFooter from '@/components/MenuFooter.vue'
import MenuFooter_En from '@/components/MenuFooterEn.vue'
export default {
    name: 'MenuDownload',
    props: {
        lang: {
            type: String,
            default: 'ko'
        },
        isMobile: {
            type: Boolean,
            default: false
        }
    },
    components: {
        MenuFooter, MenuFooter_En
    }
}
</script>

<style scoped>
@media (max-width:768px) {
    .subject {
        width: 100%;
        padding: 23px 0;
        .title {
          color: #434343;
          font-family: Pretendard;
          font-size: 19.136px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          justify-content: unset;
          height: unset;
        }
        .img {
          width: 156px;
          height: 10px;
          display: block;
          position: relative;
          top: 4px;
          right: 0px;
        }
    }
}

@media (min-width:769px) {
    .subject {
        width: 100%;
        padding: 50px 0;
        .title {
            font-family: "Pretendard-SemiBold", Helvetica;
            font-weight: 600;
            color: #434343;
            font-size: 46px;
            letter-spacing: 0;
            line-height: normal;
            white-space: nowrap;
            display: unset;
            justify-content: unset;
            align-items: unset;
            height: unset;
        }
        .img {
            width: 378px;
            height: 3px;
            display: block;
            position: relative;
            top: 17px;
            right: 45px;
        }
    }
}
.main-title {
    width: 100%;
    height: 191px;
    background-image: url(../../assets/img_legacy/s_info/s_title_back.jpg);
    background-size: 100% 100%;
    padding: 69px 0;
    .text-wrapper {
      color: #FFF;
      text-align: center;
      text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
      font-family: Pretendard;
      font-size: 25px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      display: inline-block;
      width: 100%;
    }
    .text-wrapper-2 {
      letter-spacing: 0;
      white-space: nowrap;
      color: #FFF;
      text-align: center;
      text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.5);
      font-family: Pretendard;
      font-size: 13px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      display: inline-block;
      width: 100%;
    }          
  }
</style>
