<template>
  <div class="header" :class="{ 'hovered': isHovered }" :style="isGradient? '' : 'background: rgba(0, 0, 0, 0.8) !important'">
    <img src="@/assets/img/buildmarket_logo(point_color)_Line.png" :style="'height: 30px; cursor:pointer; margin:15px ' + mainLogoMargin + 'px 20px 15px;'" @click="goMenu('/', '')"/>
    <div style="width:100vw; justify-content:center" v-show="showMenu">
      <div class="menu" @mouseover="isHovered = true" @mouseleave="isHovered = false">
        <!--<img class="earth-globe" src="@/assets/img/earth-globe.png" />-->
        <div class="name">
          <span :class="{selected: menuId?.startsWith('M2')}">내건물짓기</span>
        </div>
        <div class="name">
          <span :class="{selected: menuId?.startsWith('M3')}" @click="goMenu('/usdmtrl/list', 'M301')">중고모듈거래</span>
        </div>
        <div class="name">
          <span :class="{selected: menuId?.startsWith('M4')}" @click="goMenu('/rs/find-site', 'M401')">토지추천</span>
        </div>
        <div class="name">
          <span :class="{selected: menuId?.startsWith('M5')}">고객지원</span>
        </div>
      </div>
      <div class="menu dropdown" :class="{ 'hovered': isHovered }" @mouseover="isHovered = true" @mouseleave="isHovered = false">
        <div class="name">
          <span @click="goMenu('/plan', 'M200')">새로기획하기</span>
          <span @click="goMenu('/mhouse/model', 'M200')">주택골라보기</span>
          <span v-if="estimationOpen" @click="goMenu('/mhouse/estimation', 'M203')">주택견적내기</span>
        </div>
        <div class="name">
          <span></span>
        </div>
        <div class="name">
          <span></span>
        </div>
        <div class="name">
          <span @click="goMenu('/cs/faq', 'M501')">FAQ</span>
          <span @click="goMenu('/cs/inquiry', 'M502')">문의하기</span>
          <span @click="goMenu('/cs/download', 'M503')">자료실</span>
        </div>
      </div>
    </div>
    <div class="hm3 x_div_end" style="align-content: baseline; flex-wrap: wrap; padding-top: 22px;">
      <div class="x_div_center">
          <div class="v-container" v-if="user">
              <div class="px-1 hma2"><a class="fc-w1" href="javascript:void(0)"><span class="fkr-set1">{{ user.info.fullName + (lang == 'ko'? '님 환영합니다' : '') }}</span></a></div>
              <div class="px-1 hma2 dropdown no-arrow" style="padding-top: 2px;">
                  <div class="fc-w1 dropdown-toggle" href="#" data-bs-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="true"><i class="fa-regular fa-circle-user fc-w1"></i></div>
                  <div class="dropdown-menu" style="width:120px; margin:18px 0 0 -50px">
                      <a href="/proxypage" class="dropdown-item x_div_center py-2" style="border-bottom:1px solid #d9d9d9" v-if="user.info.adminYn && user.info.adminYn != 'N'"><span class="fen-set1 rc-2">ADMIN<br v-if="user.info.adminRole">{{ user.info.adminRole? '(' + user.info.adminRole.roleName + ')' : '' }}</span></a>
                      <a href="/panda" class="dropdown-item x_div_center py-2" style="border-bottom:1px solid #d9d9d9" v-if="user.info.cnslrLv != null"><span class="fen-set1 rc-2">PANDA</span></a>
                      <a href="/budget/prj-list" class="dropdown-item x_div_center py-2" style="border-bottom:1px solid #d9d9d9" v-if="user.info.bdgtrLv != null"><span class="fen-set1 rc-2">SANDA</span></a>
                      <a href="/ganda" class="dropdown-item x_div_center py-2" style="border-bottom:1px solid #d9d9d9" v-if="user.info.usrType == 'MA'"><span class="fen-set1 rc-2">GANDA</span></a>
                      <a href="/member/profile" class="dropdown-item x_div_center py-2"><span class="fen-set1 rc-2">PROFILE</span></a>
                      <a @click="logout()" class="dropdown-item x_div_center py-2" style="border-top:1px solid #d9d9d9"><span class="fen-set1 rc-2">LOGOUT</span></a>
                  </div>
              </div>
              <div class="px-1 hma2"><span class="fen-set1 fs-075 fc-w3">│</span></div>
          </div>
          <div class="v-container" v-else>
              <div class="px-1 hma2"><a class="fc-w1" href="#" @click="login();"><span class="fen-set1">LOGIN</span></a></div>
              <div class="px-1 hma2"><span class="fen-set1 fs-075 fc-w3">│</span></div>
          </div>
          <div class="v-container" v-if="user">
              <div class="px-1 hma2"><a class="fc-w1" href="/project/dashboard"><span class="fen-set1">{{user.info.adminYn && user.info.adminYn != 'N'? 'PRJ' : 'MY'}}</span></a></div>
              <div class="px-1 hma2"><span class="fen-set1 fs-075 fc-w3">│</span></div>
          </div>
          <div class="px-1 hma2" style="margin-right: 20px; padding-top: 1px;"><a class="fc-w1" href="#" @click="goMenu('/cs/inquiry', 'M502')"><i class="fa-regular fa-circle-question"></i></a></div>
      </div>
    </div>
    <!-- <img src="@/assets/img/homepage_header_logo(white).png" style="height: 30px; margin:20px; padding-right:10px; scale:1.5; cursor: pointer;" onclick="location.href='https:\/\/m3systems.co.kr'"/> -->
  </div>
</template>
<script>
import store from '@/store';
import Auth from '@/api/auth'
import Api from '@/api'

export default {
  name: 'Header',
  props: {
    user: {
        type: Object,
        default: null
    },
    lang: {
        type: String,
        default: 'ko'
    },
    isMobileDevice: {
        type: Boolean,
        default: false
    }
  },
  data() {
    return {
      mainLogoMargin: 300,
      isGradient: false,
      isHovered: false,
      isLogedIn: false,
      naverLogin: null,
      showMenu: true,
      estimationOpen: false
    }
  },
  async created() {
    this.url = location.href;
    if(this.url.indexOf('/project') >= 0 || this.url.indexOf('/admin') >= 0){
      this.showMenu = false;
    }
    if(this.url.indexOf('/mhouse') > 0 || this.url.indexOf('/inquiry') > 0 || this.url.indexOf('/story') > 0) {
      this.isGradient = true;
    }

    this.isLogedIn = await Auth.isLogedIn();

    if(this.user){
        if(this.user.info.usrType == 'MA' || (this.user.info.estmtList && this.user.info.estmtList.length > 0)) {
            this.estimationOpen = true;
        }
    }

    if(this.lang == 'en'){
        await Api.post('/api/v1/common/change/locale?language=' + this.lang)
    }
  },
  mounted() {
    this.mainLogoMargin = window.$('.hm3').width() - 200;

    this.naverLogin = new window.naver.LoginWithNaverId(
        {
            clientId: process.env.VUE_APP_NAVER_OAUTH_CLIENT_ID,
            callbackUrl: process.env.VUE_APP_NAVER_OAUTH_REDIRECT_URI,
            isPopup: this.isMobileDevice? false : true,
        }
    );
    this.naverLogin.init();
  },
  computed: {
    menuId() {
      return store.state.menuId;
    }
  },
  methods: {
    goMenu(menuLink, menuId) {
      store.commit('setMenuId', menuId);
      store.commit('searchParams', null);
      // this.$router.push({ name: menuNm });
      location.href = menuLink;
    },


    login() {
        this.naverLogin.logout();
        location.href = '/member/login';
    },
    async logout() {
        await Auth.logOut();
        this.naverLogin.logout();
        location.href = '/';
    },
    async toggleLanguage(language) {
        this.$emit('toggleLanguage', language);
        await Api.post('/api/v1/common/change/locale?language=' + language)
    }
  }  
}
</script>

<style scoped>
.v-container {
    display: flex;
}
</style>