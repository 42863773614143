<template>
    <div class="ca_main">
        <div class="x_div_center">
            <div class="pg_stage">

                <div class="d-flex pt-5">
                    <div style="flex:0 0 calc(100% - 100px) !important;"><span class="fa-Kor-Noto" style="font-weight:700; font-size:22pt; color:#333333">비딩 참여 관리</span></div>
                    <div class="x_div_center"><div class="text-end" style="width:100px"><span class="fa-Kor-Noto" style="font-size:13pt; color:#484848"><span class="fw-bold"></span></span></div></div>
                </div>

                <div style="height:5px; background-color:#dccccc; border-radius:10rem"></div>

                <div style="height:14px"></div>

                <div class="pg_m_set" v-if="fetching">
                    <div class="x_div_center"><div><i class='h3 fa fa-spinner fa-spin'></i></div></div>
                </div>
                <div v-else>
                    <div v-if="isMobile" class="x_div_end"><div class="py-4"><button onclick="location.href = '/project/bidding/partner/bidding-participate'" class="x_div_center" style="width:90px; height:30px; border:0; background-color:#4e0000; border-radius:4px"><span class="fa-kor-Noto fs-085 fc-w1">비딩 참여</span></button></div></div>
                    
                    <div class="x_div_center py-5">
                        <div class="text-center">
                            <div v-if="user.info.usrRole == 'DS'">
                                <span class="fkr-set2 fs-10">
                                    <span class="fw-bold" style="color:#4e0000">비딩 참여 절차 안내</span><br>
                                    비딩 참여는 프로젝트를 진행하고자 하는 건축주들과 계약을 맺을 수 있도록 선정되기 위한 과정입니다. <br/>
                                    제안 참여 의향 등록, 제안 제출, 제안 평가 결과 확인, 계약의 과정을 거치시게 되며 각 프로젝트별 약 3주의 기간이 소요됩니다.<br>
                                    참여를 원하시는 설계파트너께서는 <b>건축인허가 과정을 포함한 기획~실시설계 전반</b>의 설계업무의 수행이 가능하셔야 합니다.<br><br>
                                    <span class="fw-bold" style="color:#CC0000">M3의 비딩 절차를 통해 설계를 수주하시면 총 설계비용의 15%를 M3에 수수료로 부담하시게 됩니다.(선급금, 중도금, 잔금 등 지급단계 별 각각 차감)</span><br/>
                                    <div class="custom-control custom-checkbox x_div_center w-100">
                                        <input type="checkbox" class="custom-control-input" id="commissionAgree" v-model="commission" value="true" required >
                                        <label class="custom-control-label" for="commissionAgree">동의합니다.</label>
                                    </div>
                                          
                                    <br/><br/>
                                    <span class="fw-bold fs-12" style="color:#4e0000">1. 제안 참여 의향 표현</span><br>
                                    참여를 원하시는 프로젝트에 비딩 참여 의사를 표현하시는 단계입니다.<br/>
                                    파트너께서는 조회 시점에 요청사항이 올라온 모든 프로젝트들을 열람하실 수 있고, 각 프로젝트의 요구사항을 확인하신 후 비딩 참여 의향을 등록하실 수 있습니다.<br>
                                    참여 의향 등록 시에는 대표자 인사를 간단히 입력하시고, 프로젝트에 맞게 포트폴리오와 기타 홍보 자료를 첨부하실 수 있습니다.<br><br/>

                                    <span class="fw-bold fs-12" style="color:#4e0000">2. 제안 제출</span><br/>
                                    1에서 참여 의향을 표현하신 프로젝트들 중 건축주에 의해 비딩 참여 대상으로 선정되셨을 경우, 제안을 제출하시는 단계입니다.<br/>
                                    <b>프로젝트 요구사항을 바탕으로 한 기획설계를 필히 등록해주시기 바랍니다.</b><br/>
                                    <br/>
                                    <button style="width:120px; height:30px; border:0; background-color:#eee7e7; border:1px solid #4e0000; border-radius:4px; color:#4e0000" ><a href="/files/Designer_planning_design_spec_240911.pdf" target="_blank">기획설계 요건</a></button><br/><br/>
                                    <br/>

                                    <span class="fw-bold fs-12" style="color:#4e0000">3. 제안 평가 결과 확인</span><br/>
                                    2에서 제안을 제출하신 프로젝트들 중 건축주에 의해 계약 대상으로 선정되셨는지 여부를 확인하시는 단계입니다.<br/>
                                    1등으로 선정되셨을 경우 설계 계약의 자격을 획득하시게 됩니다.<br/>
                                    그 외의 순위에 선정되셨을 경우 다음의 기획설계 비용이 지급됩니다.<br/><br/>

                                </span>
                                <div class="table-responsive" style="border:1px solid #c9c9c9; border-radius:8px">
                                    <table class="table-borderless w-100">
                                        <tr style="height:55px; border-bottom:1px solid #c9c9c9; background-color:#eaeaea">
                                            <td class="text-center br_0"><span class="ff-kor1" style="font-size:12pt"><strong>연면적</strong></span></td>
                                            <td class="text-center"><span class="ff-kor1" style="font-size:12pt"><strong>미 선정사 수령액(부가세 별도)</strong></span></td>
                                        </tr>

                                        <tr style="height:100px; border-bottom:1px solid #c9c9c9">
                                            <td class="text-center br_0"><span class="ff-kor1" style="font-size:11pt">60평(198㎡) 이하</span></td>
                                            <td class="text-center">
                                                <table class="table-borderless w-100">
                                                    <tr style="height:50px; border-bottom:1px solid #c9c9c9">
                                                        <td class="text-center br_0"><span class="ff-eng1" style="font-size:11pt">미선정 1순위: 30만원</span></td>
                                                    </tr>
                                                    <tr style="height:50px;">
                                                        <td class="text-center br_0"><span class="ff-eng1" style="font-size:11pt">미선정 2순위: 20만원</span></td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>

                                        <tr style="height:100px; border-bottom:1px solid #c9c9c9">
                                            <td class="text-center br_0"><span class="ff-kor1" style="font-size:11pt">60~150평(495㎡)</span></td>
                                            <td class="text-center">
                                                <table class="table-borderless w-100">
                                                    <tr style="height:50px; border-bottom:1px solid #c9c9c9">
                                                        <td class="text-center br_0"><span class="ff-eng1" style="font-size:11pt">미선정 1순위: 50만원</span></td>
                                                    </tr>
                                                    <tr style="height:50px;">
                                                        <td class="text-center br_0"><span class="ff-eng1" style="font-size:11pt">미선정 2순위: 30만원</span></td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>

                                        <tr style="height:100px; border-bottom:1px solid #c9c9c9">
                                            <td class="text-center br_0"><span class="ff-kor1" style="font-size:11pt">150~300평(990㎡)</span></td>
                                            <td class="text-center">
                                                <table class="table-borderless w-100">
                                                    <tr style="height:50px; border-bottom:1px solid #c9c9c9">
                                                        <td class="text-center br_0"><span class="ff-eng1" style="font-size:11pt">미선정 1순위: 60만원</span></td>
                                                    </tr>
                                                    <tr style="height:50px;">
                                                        <td class="text-center br_0"><span class="ff-eng1" style="font-size:11pt">미선정 2순위: 40만원</span></td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                                <br>
                                ※ 미선정 3순위인 경우 수령액은 없습니다.<br/>
                                <span class="fkr-set2 fs-10">
                                    <span class="fw-bold fs-12" style="color:#4e0000"><br/><br/>4. 계약 목록</span><br/>
                                    3에서 계약 대상으로 선정된 프로젝트들의 계약을 진행하는 단계입니다.<br/><br/>
                                    <span class="fw-bold" style="color:#4e0000">비딩 참여 절차를 숙지후 비딩 참여 버튼을 클릭하세요.</span><br>
                                </span>
                            </div>
                            <span class="fkr-set2 fs-10" v-else>
                                <span class="fw-bold" style="color:#4e0000">비딩 참여 절차 안내</span><br>
                                비딩 참여는 프로젝트를 진행하고자 하는 건축주들과 계약을 맺을 수 있도록 선정되기 위한 과정입니다. <br/>
                                제안 참여 의향 등록, 제안 제출, 제안 평가 결과 확인, 계약의 과정을 거치시게 되며 각 프로젝트별 약 3주의 기간이 소요됩니다.<br><br><br/>

                                <span class="fw-bold fs-12" style="color:#4e0000">1. 제안 참여 의향 표현</span><br>
                                참여를 원하시는 프로젝트에 비딩 참여 의사를 표현하시는 단계입니다.<br/>
                                파트너께서는 조회 시점에 요청사항이 올라온 모든 프로젝트들을 열람하실 수 있고, 각 프로젝트의 도면 및 공내역을 확인하신 후 비딩 참여 의향을 등록하실 수 있습니다.<br>
                                참여 의향 등록 시에는 대표자 인사를 간단히 입력하시고, 프로젝트에 맞게 포트폴리오와 기타 홍보 자료를 첨부하실 수 있습니다.<br/><br/>

                                <span class="fw-bold fs-12" style="color:#4e0000">2. 제안 제출</span><br/>
                                1에서 참여 의향을 표현하신 프로젝트들 중 건축주에 의해 비딩 참여 대상으로 선정되셨을 경우, 금액 안을 제출하시는 단계입니다.<br/>
                                프로젝트 상세 정보를 다시 검토하신 후, 금액 안을 등록하실 수 있습니다.<br/><br/>

                                <span class="fw-bold fs-12" style="color:#4e0000">3. 제안 평가 결과 확인</span><br/>
                                2에서 제안을 제출하신 프로젝트들 중 건축주에 의해 계약 대상으로 선정되셨는지 여부를 확인하시는 단계입니다.<br/><br/>

                                <span class="fw-bold fs-12" style="color:#4e0000">4. 계약</span><br/>
                                3에서 계약 대상으로 선정된 프로젝트들의 계약을 진행하는 단계입니다.<br/>
                                <span v-if="user.info.usrRole == 'CT'">계약금액에는 M3에 납부하실 수수료가 포함됩니다(하단 요율표 참고).</span>
                                <br/><br/>
                                <div v-if="user.info.usrRole == 'CT'" class="table-responsive" style="border:1px solid #c9c9c9; border-radius:8px">
                                    <table class="table-borderless w-100">
                                        <tr style="height:55px; border-bottom:1px solid #c9c9c9; background-color:#eaeaea">
                                            <td class="text-center br_0"><span class="ff-kor1" style="font-size:12pt"><strong>총 공사금액</strong></span></td>
                                            <td class="text-center"><span class="ff-kor1" style="font-size:12pt"><strong>납부수수료율</strong></span></td>
                                        </tr>

                                        <tr style="height:45px; border-bottom:1px solid #c9c9c9" v-for="(v, i) in range" :key="i">
                                            <td class="text-center br_0">
                                                <span class="ff-kor1" style="font-size:11pt">{{ i == 0? v + '억원 이하' : (i == 10? v-1 + '억원 초과' : v-1 + '억원 초과 ' + v + '억원 이하') }}</span>
                                            </td>
                                            <td class="text-center">
                                                <span class="ff-eng1" style="font-size:11pt"> {{ (3 - 0.1*i).toFixed(1) }}% </span>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                                <div style="height:20px"></div>
                                <div class="custom-control custom-checkbox x_div_center w-100">
                                    <input type="checkbox" class="custom-control-input" id="commissionAgree" v-model="commission" value="true" required >
                                    <label class="custom-control-label" for="commissionAgree">수수료 정책에 동의합니다.</label>
                                </div>
                                <br>
                                <span class="fw-bold" style="color:#4e0000">비딩 참여 절차를 숙지후 비딩 참여 버튼을 클릭하세요.</span><br>
                            </span>
                        </div>
                    </div>
                    <div class="x_div_center"><div class="py-4"><button @click="participate()" class="x_div_center" style="width:90px; height:30px; border:0; background-color:#4e0000; border-radius:4px"><span class="fa-kor-Noto fs-085 fc-w1">비딩 참여</span></button></div></div>
                </div>
                    
                <div style="height:50px"></div>

            </div>
        </div>
    </div>
</template>

<script>
import Auth from '@/api/auth'

export default {
    name: 'BiddingPartnerInstruction',
    data() {
        return {
            fetching : false,
            isMobile : false,
            commission : false,
            range : [3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13]
        }
    },
    props: {
        user: {
            type: Object,
            default: null
        }
    },
    async mounted() {
        if(!await Auth.isLogedIn()){
           location.href = '/member/login'
        }
        else if(Auth.partnerRoleTypes.indexOf(this.user.info.usrRole) <0) {
            alert('접근권한이 없습니다.');
            location.href = '/project/dashboard';
        }
        this.isMobile = window.innerWidth < 768;
    },
    methods: {
        participate() {
            if (['DS','CT'].indexOf(this.user.info.usrRole) >= 0 && !this.commission) {
                alert('수수료 정책에 동의해주세요.');
                return false;
            }
            else {
                sessionStorage.setItem('commission_policy', 'agreed');
                location.href = '/project/bidding/partner/bidding-participate';
            }
        }
    }
}
</script>