import Vue from 'vue'
import Router from 'vue-router'
import ScheduleDashboard from '@/views/schedule_dashboard/ScheduleDashboard.vue'
import BrandStory from '@/views/mhouse/story/BrandStory.vue'
import MobileBrandStory from '@/views/mhouse/story/MobileBrandStory.vue'
import PrimitiveProject from './primitive-project'
import ProxyPage from "@/views/admin/site_mgmnt/recent/ProxyPage"
import Sicksha from './sicksha'
import Budget from './budget'
import Admin from './admin'
import Member from './member'
import Project from './project'
import Mhouse from './mhouse'
import UsedStore from './usedstore.js'
import RS from './recommendsite'
import CS from './cs'
import store from '@/store'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    ...PrimitiveProject,
    ...Sicksha,
    ...Budget,
    ...Admin,
    ...Member,
    ...Project,
    ...Mhouse,
    ...UsedStore,
    ...RS,
    ...CS,
    {
      path: '/proxypage',
      name: 'ProxyPage',
      component: ProxyPage
    },
    {
      path: '/ganda',
      component: ScheduleDashboard,
      name: 'ScheduleDashboard'
    },
    {
      path: '/',
      component: BrandStory,
      name: 'Home'
    },
    {
      path: '/mobile',
      component: MobileBrandStory,
      name: 'MobileHome'
    }
  ],
});

// 페이지 상단으로 스크롤하는 함수
function scrollToTop() {
  window.scrollTo(0, 0); // 가로 스크롤 위치와 세로 스크롤 위치를 (0, 0)으로 설정
}

// 페이지 이동 후 처리
router.afterEach((to, from) => {
  // 모델 ID 존재시 검색상태 유지
  if (!(from.params?.id || to.params?.id)) {
    store.commit('searchParams', null);
  }

  scrollToTop();
});

export default router;