<template>
  <div class="d-flex">
    <BudgetLNB/>
    <div class="ca_main">
      <div class="x_div_center">
        <div class="pg_stage">

          <div class="d-flex pt-5">
            <div style="flex:0 0 calc(100% - 200px) !important;"><span class="fa-Kor-Noto" style="font-weight:700; font-size:22pt; color:#333333">자재정보 등록</span></div>
            <div class="x_div_center"><div class="text-end" style="width:200px"><span class="fa-Kor-Noto" style="font-size:13pt; color:#484848"><span class="fw-bold"></span></span></div></div>
          </div>

          <div style="height:5px; background-color:#dccccc; border-radius:10rem"></div>

          <div style="height:20px"></div>

          <div class="w-100" style="display: flex;">
            <span class="fkr-set2 fs-10" style="padding: 5px">등록 자재 수: {{ mtrlList.length }}</span>
            <div class="ps-2" style="margin-left: auto; padding-left: 680px !important;">
              <button class="fkr-set2 fs-095" @click="openEditMtrl(null)" style="width:80px; height:35px; border:0; background-color:#4e0000; color:#fff; border-radius:4px;">자재 등록</button>
            </div>
            <div class="x_div_center" style="margin-left: auto; margin-right: 20px">
              <div class="x_div_center" style="width:245px; height:34px; border:1px solid #d6d6d6; border-radius:4px">
                <div style="padding:3px 15px 0 0; border-right:1px solid #d6d6d6" @click="findMtrl()"><i class="fa-solid fa-magnifying-glass" style="cursor:pointer"></i></div>
                <div style="padding:0 0 0 5px"><input type="text" v-model="searchWord" class="form-control fa-kor-Noto" @keyup.enter="findMtrl()" style="border:0; width:170px; height:30px; padding:0 .3rem" placeholder="항목검색"></div>
              </div>
            </div>
          </div>

            <div class="mib_box_c w-100 pt-3">
              <div class="x_div_center" style="height:55px; border-top-left-radius:.3rem; border-top-right-radius:.3rem; background-color:#eaeaea">
                <div class="x_div_center" style="width:30%"><span class="fkr-set1 fs-9">제목</span></div>
                <div class="x_div_center" style="width:30%"><span class="fkr-set1 fs-9">규격</span></div>
                <div class="x_div_center" style="width:10%"><span class="fkr-set1 fs-9">단위</span></div>
                <div class="x_div_center" style="width:20%"><span class="fkr-set1 fs-9">유형</span></div>
                <div class="x_div_center" style="width:10%"><span class="fkr-set1 fs-9">편집</span></div>
              </div>

              <div class="pg_m_set" v-if="fetching" style="padding: 20px">
                <div class="x_div_center"><div><i class='h3 fa fa-spinner fa-spin'></i></div></div>
              </div>
              <div v-else>
                <div v-for="(item, index) in mtrlListPaged" :key="index" class="x_div_center m_line_ov" style="height:55px; border-bottom:1px solid #d9d9d9">
                  <div class="x_div_center m_line" style="width:30%;"><span class="fkr-set2 fs-9">{{item.mtrlItmNm}}</span></div>
                  <div class="x_div_center m_line" style="width:30%;"><span class="fkr-set2 fs-9">{{item.stndrd}}</span></div>
                  <div class="x_div_center m_line" style="width:10%;"><span class="fkr-set2 fs-9">{{item.unit}}</span></div>
                  <div class="x_div_center m_line" style="width:20%;"><span class="fkr-set2 fs-9">{{ mtrlStyleResolver(item.mtrlStyle) }}</span></div>
                  <div class="x_div_center m_line" style="width:10%;"><span class="fkr-set2 fs-9"><a href="javascript:void(0)" @click="openEditMtrl(item)"><i class="fa fa-edit"/></a></span></div>
                </div>
              </div>
            </div>

            <!-- 페이징 -->
            <div class="x_div_center py-3" v-if="!fetching">
                <a class="px-2" @click="switchPage(-1)" style="cursor:pointer; margin-right: 10px;"><i class="fa-solid fa-angles-left"></i></a>
                <a class="px-2" @click="switchPage(0)" style="cursor:pointer; margin-right: 10px;"><i class="fa-solid fa-angle-left"></i></a>
                <span v-if="pageQueue[0] > 1" class="fen-set2" style="cursor:pointer; margin-right: 10px">...</span>
                <a v-for="index in pageQueue" :key="index" class="px-2" @click="currentPage = index; renderList();" style="margin-right: 10px"><span :class="'fen-set' + (currentPage == index? '1' : '2')" style="cursor:pointer;">{{ index }}</span></a> 
                <span v-if="pageQueue[pageQueue.length-1] < numOfPage" style="cursor:pointer;" class="fen-set2">...</span>
                <a class="px-2" @click="switchPage(1)" style="cursor:pointer; margin-right: 10px;"><i class="fa-solid fa-angle-right"></i></a>
                <a class="px-2" @click="switchPage(2)" style="cursor:pointer; margin-right: 10px;"><i class="fa-solid fa-angles-right"></i></a>
            </div>

          <div style="height:50px"></div>

        </div>
      </div>

      <div id="editMtrl" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style="z-index:10001;">
        <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">

            <div class="w-100">
              <div class="x_div_center w-100" style="background-color:#dccccc; border-top-left-radius:8px; border-top-right-radius:8px">
                <div class="w-100 p-3 ps-4"><span class="fkr-set1 fs-13">자재 편집</span></div>
                <div class="text-end pe-4" style="width:100px"><a href="#@" @click="closeModal()" style="color:#4e0000" data-dismiss="modal"><i class="fa-solid fa-xmark fs-15"></i></a></div>
              </div>

              <div class="x_div_center py-2 px-4">
                <div class="w-100">
                  <div class="mib_box_a py-3">
                      <div class="mib_a1 text-end"><span class="fkr-set1">품명</span></div>
                      <div class="mib_a2 x_div_center"></div>
                      <div class="mib_a3"><input type="text" class="form-control fkr-set2" style="width:220px; height:36px" v-model="mtrlItmNmInput"></div>
                  </div>

                  <div class="mib_box_a py-3">
                      <div class="mib_a1 text-end"><span class="fkr-set1">규격</span></div>
                      <div class="mib_a2 x_div_center"></div>
                      <div class="mib_a3"><input type="text" class="form-control fkr-set2" style="width:220px; height:36px" v-model="stndrdInput"></div>
                  </div>

                  <div class="mib_box_a py-3">
                      <div class="mib_a1 text-end"><span class="fkr-set1">단위</span></div>
                      <div class="mib_a2 x_div_center"></div>
                      <div class="mib_a3"><input type="text" class="form-control fkr-set2" style="width:220px; height:36px" v-model="unitInput"></div>
                  </div>

                  <div class="mib_box_a py-3">
                      <div class="mib_a1 text-end"><span class="fkr-set1">자재유형</span></div>
                      <div class="mib_a2 x_div_center"></div>
                      <div class="mib_a3">
                        <select class="custom-select ff-eng1 fs-085" v-model="mtrlStyleInput" style="width:220px; height:37px;">
                          <option value="11">순자재 구입비</option>
                          <option value="12">가공자재 구입비</option>
                          <option value="13">순자재 가공비</option>
                          <option value="14">자재 배송비</option>
                          <option value="21">M3직영 제조작업비</option>
                          <option value="22">파트너사 제조작업비</option>
                        </select>
                      </div>
                  </div>
                </div>
              </div>

              <div class="x_div_center py-4">
                <div class="px-2" v-if="editMode"><button @click="removeMtrl()" class="x_div_center" style="width:90px; height:30px; border:0; background-color:#eee7e7; border:1px solid #4e0000; border-radius:4px; color:#4e0000" data-dismiss="modal"><span class="fkr-set1">삭제</span></button></div>
                <div class="px-2"><button @click="closeModal()" class="x_div_center" style="width:90px; height:30px; border:0; background-color:#fff; border:1px solid #4e0000; border-radius:4px; color:#4e0000" data-dismiss="modal"><span class="fkr-set1">닫기</span></button></div>
                <div class="px-2"><button @click="setMtrl()" class="x_div_center" :disabled="submitting" style="width:90px; height:30px; border:0; background-color:#4e0000; border-radius:4px"><span class="fa-kor-Noto fs-085 fc-w1">{{ editMode? '수정' : '등록' }}<i v-if="submitting" style="margin-left: 10px" class='fa fa-spinner fa-spin'></i></span></button></div>
              </div>

              <div style="height:20px"></div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BudgetLNB from '@/components/BudgetLNB.vue'
import Auth from '@/api/auth'
import Api from '@/api'
export default {
  name: "MtrlList",
  components: {
		BudgetLNB
	},
  data(){
    return {
        fetching: false,
        // fetching: true,
        submitting: false,
        searchWord: '',
        mtrlList: [],
        mtrlListOrig:[],
        mtrlListPaged: [],
        pageQueue: [],
        currentPage: 1,
        numOfPage: 0,
        pageSize: 10,
        mtrlItmId: null,
        mtrlItmNmInput: '',
        stndrdInput: '',
        unitInput: '',
        mtrlStyleInput: '',
        editMode: true
    }
  },
  props: {
    user: {
        type: Object,
        default: null
    }
  },
  async mounted() {
    if(!await Auth.isLogedIn()){
      location.href = '/member/login'
    }

    this.currentPage = this.$route.query.p? this.$route.query.p : 1;
    this.pageSize = this.$route.query.w? this.$route.query.w : 10;
    history.replaceState({}, null, location.pathname);
    window.$('.datepicker').css('z-index', 10002)

    // await this.fetchMtrlList(null);
    this.mtrlList = [
      {
        mtrlItmId: 1,
        mtrlItmNm: 'ㄷ-CHANNEL',
        stndrd: '200*80*7.5*11',
        unit: 'kg',
        mtrlStyle: '11'
      },
      {
        mtrlItmId: 2,
        mtrlItmNm: '스터드75형 KS',
        stndrd: '2300',
        unit: 'EA',
        mtrlStyle: '11'
      },
      {
        mtrlItmId: 3,
        mtrlItmNm: '세탁기상부장',
        stndrd: '640*750*400',
        unit: 'EA',
        mtrlStyle: '12'
      },
      {
        mtrlItmId: 4,
        mtrlItmNm: '밸러스빔',
        stndrd: '마킹,용접',
        unit: '20',
        mtrlStyle: '21'
      },
      {
        mtrlItmId: 5,
        mtrlItmNm: '운반비 및 기타 장비',
        stndrd: '',
        unit: '',
        mtrlStyle: '22'
      },
    ]
    ///////////////////////////////

    this.setPaging();
    this.renderList();
  },
  methods:{
    async checkAdmin(){
      var rtnval = await Api.get('/api/v1/admin/usr/mng/find/usr/id');
      if(rtnval.result){
        let usrId = rtnval.data;

        rtnval = await Api.get('/api/v1/admin/usr/mng/find/admin/usr?usrId='+usrId);
        if(rtnval.result){
          let adminYn = rtnval.data.adminYn;
          if(!adminYn || adminYn == 'E'){
            alert('접근권한이 없습니다.');
            location.href = '/project/dashboard';
          }
        }
      }
    },

    async fetchMtrlList(keyword) {
      this.fetching = true;
      if(keyword) {
        this.mtrlList = this.mtrlListOrig.filter((item) => item.mtrlItmNm.indexOf(keyword) >= 0 );
      }
      else {
        this.mtrlList = [];
        const returnvalue = await Api.get('/api/v1/budget/mtrl/list');
        if(returnvalue.result) {
            this.mtrlList = returnvalue.data.sort((a,b) => b.mtrlItmId - a.mtrlItmId);
            this.mtrlListOrig = this.mtrlList;
        }
        else {
            alert(returnvalue.errormsg);
        }
      }
      this.fetching = false;
    },

    async findMtrl() {
      await this.fetchMtrlList(this.searchWord);
      this.setPaging();
      this.renderList();
    },

    renderList() {
      this.mtrlListPaged = [];
      for(var j = (this.currentPage-1) * this.pageSize; j < (this.mtrlList.length > this.currentPage * this.pageSize? this.currentPage * this.pageSize : this.mtrlList.length); j++) {
        this.mtrlListPaged.push(this.mtrlList[j]);
      }
    },

    setPaging() {
      this.numOfPage = Math.ceil(this.mtrlList.length / this.pageSize);
      this.pageQueue = [];
      if(this.currentPage < 11) {
        for(var i = 1; i <= (this.numOfPage > 10 ? 10 : this.numOfPage) ; i++) {
          this.pageQueue.push(i);
        }
      }
      else {
        for(i = this.currentPage - 9; i <= this.currentPage; i++) {
          this.pageQueue.push(i);
        }
      }
    },

    switchPage(mode) {
      var render = false;
      switch(mode) {
        case -1 : if(this.currentPage > 1) {
                    this.currentPage = 1; 
                    render = true;
                  }
          break;
        case 0 : if(this.currentPage > 1) {
                    this.currentPage--;
                    render = true;
                  }
          break;
        case 1 : if(this.currentPage < this.numOfPage) {
                    this.currentPage++;
                    render = true;
                  }
          break;
        case 2 : if(this.currentPage < this.numOfPage) {
                    this.currentPage = this.numOfPage;
                    render = true;
                  } 
      }
      if(render) {
        this.setPaging();
        this.renderList();
      }
    },

    openEditMtrl(mtrlToEdit) { 
      if(mtrlToEdit) {
        this.mtrlItmId = mtrlToEdit.mtrlItmId;
        this.mtrlItmNmInput = mtrlToEdit.mtrlItmNm;
        this.stndrdInput = mtrlToEdit.stndrd;
        this.unitInput = mtrlToEdit.unit;
        this.mtrlStyleInput = mtrlToEdit.mtrlStyle;
        this.editMode = true;
      }
      else {
        this.mtrlItmId = null;
        this.mtrlItmNmInput = '';
        this.stndrdInput = '';
        this.unitInput = '';
        this.mtrlStyleInput = '11';
        this.editMode = false;
      }
      window.$('#editMtrl').modal('show');
    },

    async setMtrl() {
      if(!this.mtrlItmNmInput) {
        alert('품명은 필수항목입니다.');
        return;
      }

      this.submitting = true;

      const rtnval = await Api.post('/api/v1/budget/mtrl/' + (this.editMode? 'update' : 'create'), {
        mtrlItmId: this.mtrlItmId,
        mtrlItmNm: this.mtrlItmNmInput,
        stndrd: this.stndrdInput,
        unit: this.unitInput,
        mtrlStyle: this.mtrlStyleInput
      })
      if(rtnval.result) {
        alert('정상적으로 등록되었습니다.');
        window.$('#editMtrl').modal('hide');
        await this.fetchMtrlList(null);
        this.setPaging();
        this.renderList();
      }
      else {
        alert(rtnval.errormsg)
      }
      this.submitting = false;
    },

    async removeMtrl() {
      if(confirm('해당 자재를 삭제하시겠습니까?')) {
        this.submitting = true;
        const rtnval = await Api.get('/api/v1/budget/mtrl/delete?mtrlItmId=' + this.mtrlItmId)
        if(rtnval.result) {
          alert('정상적으로 삭제되었습니다.');
          window.$('#editMtrl').modal('hide');
          await this.fetchMtrlList(null);
          this.setPaging();
          this.renderList();
        }
        else {
          alert(rtnval.errormsg)
        }
        this.submitting = false;
      }
    },

    mtrlStyleResolver(style) {
      switch(style) {
        case "11" : return '순자재 구입비'
          break;
        case "12" : return '가공자재 구입비'
          break;
        case "13" : return '순자재 가공비'
          break;
        case "14" : return '자재 배송비'
          break;
        case "21" : return 'M3직영 제조작업비'
          break;
        case "22" : return '파트너사 제조작업비'
          break;
      }
    },

    closeModal() {
			window.$('#editMtrl').modal('hide');
		},
  }
}
</script>

<style scoped>
.mib_box_a {
    width: auto;
}
</style>
