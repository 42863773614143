<template>
    <div class="ca_menu" :style="'min-height:100%; padding-top:65px; border-right:1px solid #c9c9c9; width:' + (collapsed? '53px' : '100%')">

        <div class="accordion-item"> 

          <div>
            <div id="heading" class="accordion-header x_div_center px-3" style="height:55px; border-bottom:1px solid #c9c9c9">
                <div class="accordion-button collapsed x_div_center" type="button" data-bs-toggle="collapse" data-bs-target="#collapse" aria-expanded="false" aria-controls="collapse">
                    <div><div :class="'ms_icon1' + (url.indexOf('/budget/') > 0 ? '_on' : '')"></div></div>
                    <div v-show="!collapsed" class="sm_ms2 w-100 ps-2"><span class="ms_fa1 fw-bold">실행예산 관리</span></div>
                    <div v-show="!collapsed" class="sm_ms2"><img src="@/assets/img_legacy/ms_arrow.png"></div>
                </div>
            </div>

            <div v-show="!collapsed" id="collapse" :class="'sm_ms2 accordion-collapse collapse' + (url.indexOf('/budget/') > 0? ' show' : '')" aria-labelledby="heading" data-bs-parent="#m3_menu">
                <div class="accordion-body p-3">
                    <div :class="'side_menu_m w-100 x_div_center ms_bg1' + (url.indexOf('/budget/mtrl-list') > 0? '_on' : '')" style="height:36px; padding-left:40px"><div class="w-100 text-start"><a class="ms_fa1" href="/budget/mtrl-list">기본자재정보 등록</a></div></div>
                    <div :class="'side_menu_m w-100 x_div_center ms_bg1' + (url.indexOf('/budget/prj-list') > 0? '_on' : '')" style="height:36px; padding-left:40px"><div class="w-100 text-start"><a class="ms_fa1" href="/budget/prj-list">프로젝트 목록</a></div></div>
                </div>
            </div>
          </div>          

        </div>

        <div class="x_div_end" style="padding:50px 8px 50px 0">
            <div @click="collapsed = !collapsed" :class="'x_div_end ar_btn' + (collapsed? '' : ' sec')"></div>
        </div>

    </div>
</template>

<script>
export default {
    name: 'BudgetLNB',
    data() {
        return {
            collapsed: false,
            url: '',
        }
    },
    async created() {
      this.url = location.href;
    }
}
</script>