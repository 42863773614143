import BudgetExecuteList from '@/views/budget/BudgetExecuteList.vue'
import PrjList from '@/views/budget/PrjList.vue'
import MtrlList from '@/views/budget/MtrlList.vue'

export default [
    {
        path: '/budget/mtrl-list',
        name: 'MtrlList',
        component: MtrlList
    },
    {
        path: '/budget/prj-list',
        name: 'PrjList',
        component: PrjList
    },
    {
        path: '/budget/execute/:prjId',
        name: 'BudgetExecuteList',
        component: BudgetExecuteList
    },
]