<template>
  <div class="mobile-header" :class="{overlap: showMenu}">
    <div class="menu-kor">
      <div class="overlap-group-wrapper">
        <div class="overlap-group">
          <div class="logo-area">
            <img src="@/assets/img/buildmarket_logo(point_color)_Line.png" style="height: 20px; margin:10px" @click="goMenu('/mobile', '')"/>
            <img @click="showMenuList()" src="@/assets/img/common/menu.svg"/>
          </div>
          <ul v-if="!prjMenu" class="menu-list">
            <li style="padding-right:15px">
              <div class="x_div_center p-1" v-if="isLogedIn" style="display: flex; justify-content: space-between;">
                  <div class=""><div class="fkr-set1 fs-095 fc-w8">{{ user.info.fullName }}</div></div>
                  <div class="x_div_center p-1">
                      <div class="" style="width:auto"><div class="x_div_center box-type-1" v-if="user.info.cnslrLv != null"><a href="/panda"><span class="fkr-set1 fc-w3"><i class="fas fa-headset"></i></span></a></div></div>
                      <div class="" style="width:auto"><div class="x_div_center box-type-1" v-if="user.info.usrType == 'MA'"><a href="/ganda"><span class="fkr-set1 fc-w3"><i class="far fa-calendar-alt"></i></span></a></div></div>
                      <div class="" style="width:auto"><div class="x_div_center box-type-1"><a href="/project/dashboard"><span class="fkr-set1 fc-w3">MY</span></a></div></div>
                      <div class="" style="width:auto"><div class="x_div_center box-type-1"><a href="/member/profile"><span class="fkr-set1 fc-w3"><i class="fas fa-cog"></i></span></a></div></div>
                      <div class="" style="width:auto"><div class="x_div_center box-type-1"><a  @click="logout()"><span class="fkr-set1 fc-w3">로그아웃</span></a></div></div>
                  </div>
              </div>
              <div class="x_div_center p-1" v-else>
                  <div class="w-100"><div class="fkr-set1 fs-095 fc-w8">로그인이 필요합니다</div></div>
                  <div class="" style="width:80px"><div class="x_div_center box-type-1"><a href="#" @click="login();"><span class="fkr-set1 fc-w3">로그인</span></a></div></div>
              </div>
            </li>
            <li>
              <span>내건물짓기</span>
              <ul class="submenu">
                <li>
                  <span @click="goMenu('/plan', 'M201')">새로기획하기</span>
                </li>
                <li>
                  <span @click="goMenu('/mobile/mhouse/model', 'M200')">주택골라보기</span>
                </li>
                <li>
                  <span v-if="estimationOpen" onclick="alert('PC웹에서만 이용 가능한 서비스입니다.')">실시간견적산출</span>
                </li>
              </ul>
            </li>
            <li>
              <span @click="goMenu('/usdmtrl/list', 'M301')">중고모듈거래</span>
            </li>
            <li>
              <span @click="goMenu('/rs/find-site', 'M401')">토지추천</span>
            </li>
            <li>
              <span>고객지원</span>
              <ul class="submenu">
                <li>
                  <span @click="goMenu('/cs/faq', 'M501')">FAQ</span>
                </li>
                <li>
                  <span @click="goMenu('/mobile/cs/inquiry', 'M502')">문의하기</span>
                </li>
                <li>
                  <span @click="goMenu('/cs/download', 'M503')">자료실</span>
                </li>
              </ul>
            </li>
          </ul>

          <!-- 반응형 레이어메뉴 시작(프로젝트메뉴) { -->
          <div v-else class="m_nav" id="navbar_amg2" style="display:block !important">

            <!-- 로그인 -->
            <div class="px-3 py-2" style="border-bottom:1px solid #e1e1e1">
                <div class="x_div_center p-1" v-if="isLogedIn" style="display: flex; justify-content: space-between;">
                    <div class=""><div class="fkr-set1 fc-w10">{{ user.info.fullName }}</div></div>
                    <div class="x_div_center p-1">
                        <div class="" style="width:auto"><div class="x_div_center box-type-2" v-if="user.info.cnslrLv != null"><a href="/panda"><span class="fkr-set1 fc-w3"><i class="fas fa-headset"></i></span></a></div></div>
                        <div class="" style="width:auto"><div class="x_div_center box-type-2" v-if="user.info.usrType == 'MA'"><a href="/ganda"><span class="fkr-set1 fc-w3"><i class="far fa-calendar-alt"></i></span></a></div></div>
                        <div class="" style="width:auto"><div class="x_div_center box-type-2"><a href="/member/profile"><span class="fkr-set2 fc-w3"><i class="fas fa-cog"></i></span></a></div></div>
                        <div class="" style="width:auto"><div class="x_div_center box-type-2" v-if="user.info.cnslrLv == null"><a href="/mobile/cs/inquiry"><span class="fkr-set2">문의하기</span></a></div></div>
                        <div class="" style="width:auto"><div class="x_div_center box-type-2"><a  @click="logout()"><span class="fkr-set2">로그아웃</span></a></div></div>
                    </div>
                </div>
            </div>

            <!-- 메인메뉴 -->
            <ul class="accordion p-4">
                <li class="d-flex px-2 py-3"><img src="@/assets/img_legacy/ms_m1_on.png" :class="'pe-2' + (url.indexOf('/project/dashboard') > 0 ? '' : ' off')"> <a href="/project/dashboard"><span class="fen-set1 fs-095" style="color:#4e0000">My Projects</span></a></li>
                <div :style="dashboard? 'pointer-events: none; opacity: 0.3' : ''">
                    <li class="d-flex px-2 py-3"><img src="@/assets/img_legacy/ms_m2_on.png" :class="'pe-2' + (url.indexOf('/project/summary/') > 0 ? '' : ' off')"> <a :href="'/project/summary/' + prjId"><span class="fkr-set1 fs-09" style="color:#4e0000">개요</span></a></li>

                    <div v-for="(pr, index) in pr" :key="'pr' + index">
                      <div v-if="prcAuth.indexOf(pr.code) >= 0">
                          <li class="d-flex px-2">
                              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="'#ampr_' + pr.orderNum+1" aria-expanded="true" :aria-controls="'#ampr_' + pr.orderNum+1"><img src="@/assets/img_legacy/ms_m3_on.png" :class="'pe-2' + (url.indexOf('/project/' + pr.code) > 0 ? '' : ' off')"> <span class="fkr-set1 fs-09">{{ pr.name }}</span></button>
                          </li>
                          <li :id="'ampr_' + pr.orderNum+1" :class="'accordion-collapse collapse' + (url.indexOf('/project/' + pr.code) > 0 ? ' show' : '')">
                              <div class="accordion-body py-3">
                                  <ul>
                                      <li :class="'w-100 p-2 ps-3 ms_bg1' + (url.indexOf('/project/' + pr.code + '/summary') > 0 ? '_on' : '')" style="border:0"><a class="ms_fa1" :href="'/project/' + pr.code + '/summary'">개요</a></li>
                                      <li v-for="(prcStp, kintex) in pr.prcStpList" :key="kintex"
                                          :class="'w-100 p-2 ps-3 ms_bg' + (prcStp.started? '1' : '2') + (url.indexOf('/project/' + pr.code + '/' + prcStp.code) > 0 ? '_on' : '')" style="border:0"><a class="ms_fa1" :href="'/project/' + pr.code + '/' + prcStp.code + (pr.code == 'DS'? '/dsgn' : '')">{{ prcStp.name }}</a>
                                      </li>
                                      <li v-if="pr.code == 'DS'" :class="'w-100 p-2 ps-3 ms_bg' + (dsgnCompleted? '1' : '2') + (url.indexOf('/project/' + pr.code + '/design-info') > 0 ? '_on' : '')" style="border:0"><a class="ms_fa1" :href="'/project/' + pr.code + '/dsgn-info'">설계요약</a></li>
                                  </ul>
                              </div>
                          </li>
                      </div>
                    </div>

                    <div v-if="prc8">
                        <!-- 제조/시공/인테리어 -->
                        <li class="d-flex px-2"><button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#am_4" aria-expanded="true" aria-controls="am_4"><img src="@/assets/img_legacy/ms_m4_on.png" :class="'pe-2' + (currentPrcCd.indexOf(prcCd) >= 0 ? '' : ' off')"> <span class="fkr-set1 fs-09">{{ prcTitles }}</span></button></li>
                    </div>
                    
                    <li id="am_4" :class="'accordion-collapse collapse' + (currentPrcCd.indexOf(prcCd) >= 0 ? ' show' : '')">
                        <div class="accordion-body py-3">
                            <div v-for="(prc, index) in prc" :key="index">
                                <div v-if="prcAuth.indexOf(prc.code) >= 0">
                                    <div><span class="fkr-set1 fs-09 fc-w10">{{ prc.name }}</span></div>
                                    <ul>
                                        <li :class="'w-100 p-2 ps-3 ms_bg1' + (url.indexOf('/project/' + prc.code + '/summary') > 0 ? '_on' : '')" style="border:0"><a class="ms_fa1" :href="'/project/' + prc.code + '/summary'">개요</a></li>
                                        <li v-for="(prcStp, kintex) in prc.prcStpList" :key="kintex"
                                            :class="'w-100 p-2 ps-3 ms_bg' + (prcStp.started? '1' : '2') + (url.indexOf('/project/' + prc.code + '/' + prcStp.code) > 0 ? '_on' : '')" style="border:0"><a class="ms_fa1" :href="'/project/' + prc.code + '/' + prcStp.code">{{ prcStp.name }}</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </li>

                    <!-- 운송/설치/유지보수 -->
                    <div v-for="(prcs, index) in prcs" :key="index">
                        <div v-if="prcAuth.indexOf(prcs.code) >= 0">
                            <li class="d-flex px-2">
                              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="'#am_' + prcs.orderNum+1" aria-expanded="true" :aria-controls="'am_' + prcs.orderNum+1"><img :src="'/./files/img_legacy/ms_m' + (prcs.orderNum < 6? prcs.orderNum+1 : 7) + '_on.png'" :class="'pe-2' + (url.indexOf('/project/' + prcs.code) > 0 ? '' : ' off')"> <span class="fkr-set1 fs-09">{{ prcs.name }}</span></button>
                            </li>
                            <li :id="'am_' + prcs.orderNum+1" :class="'accordion-collapse collapse' + (url.indexOf('/project/' + prcs.code) > 0 ? ' show' : '')">
                                <div class="accordion-body py-3">
                                    <ul>
                                        <li v-if="prcs.code == 'DE'" :class="'w-100 p-2 ps-3 ms_bg1' + (url.indexOf('/project/' + prcs.code + '/summary') > 0 ? '_on' : '')" style="border:0"><a class="ms_fa1" :href="'/project/' + prcs.code + '/summary'">개요</a></li>
                                        <li v-for="(prcStp, kintex) in prcs.prcStpList" :key="kintex"
                                            :class="'w-100 p-2 ps-3 ms_bg' + (prcStp.started? '1' : '2') + (url.indexOf('/project/' + prcs.code + '/' + prcStp.code) > 0 ? '_on' : '')" style="border:0"><a class="ms_fa1" :href="'/project/' + prcs.code + '/' + prcStp.code">{{ prcStp.name }}</a>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                        </div>
                    </div>
                </div>
            </ul>

          </div>
          <!-- 반응형 레이어메뉴 끝(프로젝트메뉴) { -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import store from '@/store';
import Auth from '@/api/auth'
import Api from '@/api'

export default {
  name: 'MobileHeader',
  props: {
    user: {
        type: Object,
        default: null
    },
    lang: {
        type: String,
        default: 'ko'
    }
  },
  data() {
    return {
      url: null,
      isLogedIn: false,
      naverLogin: null,
      showMenu: false,
      prjMenu: false,
      dashboard: false,
      adminYn: 'N',
      prjId: null,
      pr: [],
      prc: [],
      prcs: [],
      prcCd: [],
      prcAuth: [],
      prc8: false,
      currentPrcCd: '',
      prcTitles: '',
      dsgnCompleted: false,
      estimationOpen: false
    }
  },
  async created() {
    this.url = location.href;
    this.prjMenu = this.url.indexOf('/project') >= 0;
    if(this.url.indexOf('/project/dashboard') >= 0 || this.url.indexOf('/project/bidding') >= 0){
        this.dashboard = true;
    }

    this.isLogedIn = await Auth.isLogedIn();

    if(this.user){
        if(this.user.info.usrType == 'MA' || (this.user.info.estmtList && this.user.info.estmtList.length > 0)) {
          this.estimationOpen = true;
        }
        var rtnval = await Api.get('/api/v1/admin/usr/mng/find/admin/usr?usrId=' + this.user.id);
        if(rtnval.result && rtnval.data.adminYn){
            this.adminYn = rtnval.data.adminYn;
        }
    }

    if(this.lang == 'en'){
        await Api.post('/api/v1/common/change/locale?language=' + this.lang)
    }
  },
  async mounted() {
    this.prjId = sessionStorage.getItem('prjId');
    if(this.prjId && this.prjId != 'null') {
        this.currentPrcCd = this.url.split('/project/')[1].split('/')[0];
        this.prcAuth = JSON.parse(sessionStorage.getItem('accessiblePrc'));
        const prcTree = JSON.parse(sessionStorage.getItem('prcTree'));
        prcTree.processList.forEach((prc, index) => {
            if(prc.code == 'DS') {
                this.pr.push(prc);
            }
            else {
                this.prcTitles += prc.name + (index+1 < prcTree.processList.length? ' / ' : '');
                this.prc.push(prc);
                this.prcCd.push(prc.code);
                if(!this.prc8) {
                    this.prc8 = this.prcAuth.indexOf(prc.code) >= 0? true : false;
                }
            }
        })
        prcTree.prcScndryList.forEach((prcs) => {
            if(prcs.code == 'DH') {
                var prHead = [prcs];
                this.pr = prHead.concat(this.pr);
            }
            else {
                this.prcs.push(prcs);
            }
        })

        const rtnval = await Api.get('/api/v1/project/find/prj/dtl/smmry?prjId=' + this.prjId);
        if(rtnval.result) {
            rtnval.data.prcInfoDto.forEach((dto) => {
                if(['DS', 'DH'].indexOf(dto.prcTyp) >= 0) {
                    this.pr.forEach((pr) => {
                        if(pr.code == dto.prcTyp) {
                            pr.prcStpList.forEach((prcStp) => {
                                prcStp.started = prcStp.orderNum <= dto.prcStpOrdNumMax? true : false;
                            })
                            if(dto.prcTyp == 'DS' && dto.prcStpOrdNumMax+1 == pr.prcStpList.length && dto.prcStts.indexOf("완료") >= 0) {
                                this.dsgnCompleted = true;
                            }
                        }
                    })
                }
                else {
                    this.prc.forEach((prc) => {
                        if(prc.code == dto.prcTyp) {
                            prc.prcStpList.forEach((prcStp) => {
                                prcStp.started = prcStp.orderNum <= dto.prcStpOrdNumMax? true : false;
                            })
                        }
                    })
                    if(['MA', 'DE'].indexOf(dto.prcTyp) >= 0) {
                        this.prcs.forEach((prcs) => {
                            prcs.prcStpList.forEach((prcStp) => {
                                prcStp.started = prcStp.orderNum <= dto.prcStpOrdNumMax? true : false;
                            })
                        })
                    }
                }
            })
            if(rtnval.data.prj.prjCmpltnYn) {
                this.prcs[this.prcs.length-1].prcStpList.forEach((prcStp) => {
                    prcStp.started = true;
                })
            }
            this.$forceUpdate();
        }
        else {
            alert(rtnval.errormsg)
        }
    }

    this.naverLogin = new window.naver.LoginWithNaverId(
        {
            clientId: process.env.VUE_APP_NAVER_OAUTH_CLIENT_ID,
            callbackUrl: process.env.VUE_APP_NAVER_OAUTH_REDIRECT_URI,
            isPopup: this.isMobileDevice? false : true,
        }
    );
    this.naverLogin.init();
  },
  beforeDestroy() {
    document.body.classList.remove('hidden');
  },  
  methods: {
    showMenuList() {
      this.showMenu = !this.showMenu;

      if(this.prjMenu) {
        if(window.$('#navbar_amg2').css('height') == '0px'){
            window.$('#navbar_amg2').css('height', '100%');
        }
        else {
            window.$('#navbar_amg2').css('height', 0);
        }
      }
      else {
        // 메뉴 위치 고정
        if (this.showMenu) {
          document.body.classList.add('hidden');
        } else {
          document.body.classList.remove('hidden');
        }
      }
    },
    goMenu(menuLink, menuId) {
      this.showMenu = false;
      store.commit('setMenuId', menuId);
      store.commit('searchParams', null);
      document.body.classList.remove('hidden');
      // this.$router.push({ name: menuNm });
      location.href = menuLink;
    },


    login() {
        this.naverLogin.logout();
        location.href = '/member/login';
    },
    async logout() {
        await Auth.logOut();
        this.naverLogin.logout();
        location.href = '/';
    },
    async toggleLanguage(language) {
        this.$emit('toggleLanguage', language);
        await Api.post('/api/v1/common/change/locale?language=' + language)
    }
  }
}
</script>

<style scoped>

#navbar_amg2 {
    background-color:#fff;
    position:fixed;
    width: 100%;
    height: 0;
    overflow:auto;
    transition: 0.2s;
}
.m_nav ul {
  padding:0px;
	margin:0px;
	list-style-type:none;
  gap: unset !important;
}

.m_nav li {
  width:100%;
  padding:0px;
	margin:0px;
	list-style-type:none;
	/* display:block; */
	border-bottom:1px solid #e1e1e1;
}

.pe-2.off {
    opacity: 0.3;
}

.ms_bg1 a {
  font-size: .85rem;
}
.ms_bg2 a {
  font-size: .85rem;
}
.ms_bg1_on a {
  font-size: .85rem;
}
.ms_bg2_on a {
  font-size: .85rem;
}

.p-4 ul {
  padding: unset !important;
}
</style>