<template>
  <div id="mobileHouseModelDetail" class="model-detail-page">
    <div class="top-area">
      <div class="top">
        <div class="item">
          <a href="/cs/faq"><img src="@/assets/img/model/detail/ellipse-12.png" /></a>
          <span class="how">모듈러주택</span>
          <span class="name">모듈러주택이 궁금하시다면</span>
        </div>
        <div class="item">
          <a href="/plan"><img src="@/assets/img/model/detail/ellipse-14.png" /></a>
          <span class="how">주택추천</span>
          <span class="name">나에게 딱맞는 집을 경험하세요</span>
        </div>
        <div class="item">
          <a href="/mobile/cs/inquiry"><img src="@/assets/img/model/detail/ellipse-13.png" /></a>
          <span class="how">고객상담</span>
          <span class="name">구매 전, 저희와 상담하세요</span>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="item-area">
        <div class="imagebox">
          <img :src="represent" />
        </div>
        <div class="description">
          <span class="type">{{ modelDetail.model_name }}</span>
          <span style="font-size: 25px;">{{ modelDetail.id }}</span>
          <span class="short-description-about-model">
            {{ modelDetail.model_title }}
          </span>
          <div class="txt">
            {{ modelSpec }}
          </div>          
          <div class="price-area">
            <span class="price">{{ modelDetail.cost.toLocaleString() }}</span><span>WON</span>
            <div class="help" @click="showCostInfo()">
              <span class="i">i</span>
              <span class="round"></span>
            </div>
            <div class="group-165">
            </div>
          </div>
          <div class="btn-area" style="top: unset; bottom: 0; padding: 20px 0 20px 0; background-color: #fff;">
            <button @click="goMenu('MobileQuestion')">문의하기</button>
          </div>
          <div v-if="isShowCostInfo" class="modals">
            <div class="overlap" @click="closeCostInfo()"></div>
            <div class="modal-contents">
              <div class="modal-headers">
                <p>가격정보</p>
              </div>
              <div class="modal-bodys">
                <ul>
                  <li><span>기초토목공사 인허가 운송 설치 및 부가세 별도</span></li>
                  <li><span>일부 인테리어 포함 가격입니다. (붙박이장 등)</span></li>
                  <li><span>옵션사항에 따라 가격에 변동이 있을 수 있습니다.</span></li>
                </ul>
              </div>
            </div>
          </div>          
        </div>
      </div>
      <!-- 맞춤 주택 추천받기 -->
      <custom-home-recommendation />
      <div class="center-area">
        <div class="center-item">
          <span class="model">{{ modelDetail.id }}</span>
          <span>{{ `${modelDetail.material_name} ${modelDetail.type_name} 주택` }}</span>
          <span class="premium-model">{{ modelDetail.model_name }}</span>
          <div class="option-area">
            <div class="option">
              <div class="option-item">
                <img src="@/assets/img/model/detail/area-1.png" />
              </div>
              <span>{{`${(modelDetail.exclusive_area[0] * 3.3).toFixed(2)}m²[${modelDetail.exclusive_area[0]}PY]`}}</span>
            </div>
            <!--
            <div class="option">
              <div class="option-item">
                <img src="@/assets/img/model/detail/tile.png" />
              </div>
              <span>2 Floors</span>
            </div>
            -->
            <div class="option">
              <div class="option-item">
                <div class="ellipse-39"></div>
                <img src="@/assets/img/model/detail/room.png" />
              </div>
              <span>{{`${modelDetail.num_of_room} Room`}}</span>
            </div>            
            <div class="option">
              <div class="option-item">
                <img src="@/assets/img/model/detail/public-toilet.png" />
              </div>
              <span>{{`${modelDetail.num_of_bath} Toliet`}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="center-desc-area">
        <div class="item-area">
          <img :src="description" />
          <div class="desc-area">
            <span class="model">{{ modelDetail.id }}</span>
            <span class="name">{{ `${modelDetail.material_name} ${modelDetail.type_name} 주택` }}</span>
            <span class="option">
              {{ `${modelDetail.model_name} | ${modelSpec}` }}
            </span>
            <span>{{ modelDetail.model_desc }}</span>
          </div>
        </div>
      </div>
      <div v-if="modelDetail.id == 'PRD-10'" class="center-desc-area">
        <div class="item-area">
          <img src="@/assets/img/E1_installation.png" />
        </div>
      </div>
      <div class="floor-plan-area">
        <span class="title">FLOOR PLAN</span>
        <div class="floor-area">
          <div class="group">
            <div class="item-box image" style="height:120px">
              <transition name="fade">
                <div v-show="selectedFloor === '01'" class="fade-imagebox">
<!--                  <img src="@/assets/img/model/detail/_1-jm-230421-ver-05-1.png" />-->
                    <img :src="floorplans[0]" width="636"/>
                </div>
              </transition>
              <transition name="fade" v-if="floorplans.length > 1">
                <div v-show="selectedFloor === '02'" class="fade-imagebox">
<!--                  <img src="@/assets/img/model/detail/jm-230421-ver-051.png" />-->
                    <img :src="floorplans[1]" width="636"/>
                </div>
              </transition>
            </div>            
            <div class="item-box desc">
              <div class="info-area" :class="{on: selectedFloor === '01'}">
                <div class="floor">
                  <div class="circle" @click="selectedFloor = '01'">
                    <span>1F</span>
                  </div>
                </div>
                <div class="text">
                  <span class="area">1층 면적</span>
                  <span>{{`${(modelDetail.exclusive_area[0] * 3.3).toFixed(2)}m²`}}</span>
                  <span class="type">{{`${modelDetail.exclusive_area[0]}PY`}}</span>
                </div>
              </div>
              <div class="info-area" :class="{on: selectedFloor === '02'}" v-if="floorplans.length > 1">
                <div class="floor">
                  <div class="circle" @click="selectedFloor = '02'">
                    <span>2F</span>
                  </div>
                </div>
                <div class="text">
                  <span class="area">2층 면적</span>
                  <span>{{`${(modelDetail.exclusive_area[1] * 3.3).toFixed(2)}m²`}}</span>
                  <span class="type">{{`${modelDetail.exclusive_area[1]}PY`}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="carousel-area">
        <span class="title">OPTIONS</span>      
        <vueper-slides
          class="no-shadow thumbnails"
          :slide-ratio="1 / 4"
          fixed-height="172px"
          :arrows="true"
          :bullets="false"
          :dragging-distance="50"
          transition-speed="250"          
        >
          <vueper-slide
            v-for="(src, i) in options"
            :key="i">
            <template v-slot:content>
              <div class="item">
                <img :src="src" width="100%" height="100%" style="scale: 1.3">
              </div>
            </template>
          </vueper-slide>
        </vueper-slides>
        <div class="text-item">
          <span>{{ modelDetail.option_desc }}</span>
        </div>
      </div>
      <div class="more-views-area">
        <span class="title">MORE VIEWS</span>
        <div class="view-area">
          <div class="image-item">
            <div class="view">
              <img v-for="(src, idx) in moreViews" :key="idx" class="img" :src="src" @click="showCarouselInfo(idx)" />
            </div>
          </div>
          <div class="video-area" style="display: none">
            <div class="video">
              <img src="@/assets/img/model/detail/_250723-6-1.png" />
              <img class="play" src="@/assets/img/common/play.svg" />
            </div>
            <div class="video">
              <img src="@/assets/img/model/detail/_250723-6-1.png" />
              <img class="play" src="@/assets/img/common/play.svg" />
            </div>            
          </div>
        </div>
      </div>
      <div class="btn-area" style="width: 100%;">
        <button class="submit" type="submit" @click="goMenu('MobileHouseModelList')"><span>목록보기</span></button>
      </div>
    </div>
    <div v-if="isShowCarouselInfo" class="carousel-modals" :style="'top:' + moreViewsModalTopPosition">
      <div class="overlap"></div>
      <div class="modal-contents">
        <div class="modal-headers">
          <div class="btn-area">
            <img src="@/assets/img/model/x.svg" @click="closeCarouselInfo"/>
          </div>
        </div>
        <div class="modal-bodys">
          <vueper-slides
          class="no-shadow thumbnails"
          :slide-ratio="1 / 4"
          :fixed-height="moreViewsModalHeight"
          :arrows="true"
          :bullets="false"
          :dragging-distance="50"
          transition-speed="250"          
        >
          <vueper-slide v-for="(src, i) in moreViewsSwitched" :key="i">
            <template v-slot:content>
              <div class="item">
                <img :src="src" style="width:90vw">
              </div>
            </template>
          </vueper-slide>
        </vueper-slides>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { VueperSlides, VueperSlide } from 'vueperslides';
import 'vueperslides/dist/vueperslides.css';
import { dataMixin } from '@/mixins/dataMixin';
import store from '@/store';
import CustomHomeRecommendation from '@/components/CustomHomeRecommendation.vue';

export default {
  name: "MobileHouseModelDetail",
  mixins: [dataMixin],
  components: {
    VueperSlides,
    VueperSlide,
    CustomHomeRecommendation
  },
  data() {
    return {
      modelDetail: {}, // 메뉴 상세 데이터
      selectedFloor: '01', // 층 정보 (현재 사용안함)
      isShowCostInfo: false, // 가격정보 도움말
      isShowCarouselInfo: false, // MORE VIEWS 이미지 팝업
      moreViewsSwitched: [], // MORE VIEWS 목록에서 항목 클릭시 해당 이미지를 시작으로 재정렬한 moreViews
      moreViewsModalHeight: '56vw', // MORE VIEWS 모달 높이
      moreViewsModalTopPosition: '40%', // MORE VIEWS 모달 상단 위치
      showCarouselIndex: 0  // MORE VIEWS 캐러셀 현재 index
    };
  },
  created() {
    this.modelDetail = this.getModelDetail(this.$route.params.id);
  },
  mounted() { 
    window.$(document).on('click','button.vueperslides__arrow--next', () => {
      this.showCarouselIndex++;
      if(this.showCarouselIndex == this.moreViews.length) {
        this.showCarouselIndex = 0;
      }
      const img = new Image();
      img.src = this.moreViewsSwitched[this.showCarouselIndex];
      this.moreViewsModalHeight = img.width - img.height > 0? '56vw' : '80vh';
      this.moreViewsModalTopPosition = img.width - img.height > 0? '40%' : '10%';
    })
    window.$(document).on('click','button.vueperslides__arrow--prev', () => {
      this.showCarouselIndex--;
      if(this.showCarouselIndex == -1) {
        this.showCarouselIndex = this.moreViews.length -1;
      }
      const img = new Image();
      img.src = this.moreViewsSwitched[this.showCarouselIndex];
      this.moreViewsModalHeight = img.width - img.height > 0? '56vw' : '80vh';
      this.moreViewsModalTopPosition = img.width - img.height > 0? '40%' : '10%';
    })
  },
  computed: {
    /* 메뉴 정보 */
    menuId() {
      return store.state.menuId;
    },
    /* 모델 상세 정보 */
    modelSpec() {
      const { exclusive_area, num_of_room, num_of_bath } = this.modelDetail;
      const exclusiveArea = `${(exclusive_area[0] * 3.3).toFixed(2)}m² [${exclusive_area[0]}PY]`;
      const numOfRoom = `${num_of_room}Room`;
      const numOfBath = `${num_of_bath}Toliet`;

      return [exclusiveArea, numOfRoom, numOfBath].join(" | ");
    },
    represent() {
        var target = this.modelDetail.imageList[0];
        this.modelDetail.imageList.forEach(function(item){
            if(item.includes('represent')){
                target = item;
            }
        });
        return target;
    },
    description() {
        var target = this.modelDetail.imageList[1];
        this.modelDetail.imageList.forEach(function(item){
            if(item.includes('description')){
                target = item;
            }
        });
        return target;
    },
    floorplans() {
        var target = this.modelDetail.imageList.filter(function(data) {
            return data.includes('floorplan');
        });
        return target;
    },
    options() {
        var target = this.modelDetail.imageList.filter(function(data) {
            return data.includes('options');
        });
        return target;
    },
    /* MORE VIEWS 이미지는 전체 이미지 경로에서 마지막에 해당하는 파일명이 _로 시작하는 것들 */
    moreViews() {
        var target = this.modelDetail.imageList.filter(function(data) {
            return data.split('/').slice(-1)[0].startsWith('_');
        });
        return target;
    }
  },
  methods: {
    /* 메뉴 이동 */
    goMenu(menuNm) {
      if(menuNm == 'MobileQuestion') {
        this.$router.push({ name: menuNm, query: { id: this.$route.params.id } });  
      }
      else {
        this.$router.push({ name: menuNm });
      }
    },
    /* 가격정보 팝업 보이기 */
    showCostInfo() {
      this.isShowCostInfo = true;
    },
    /* 가격정보 팝업 감추기 */
    closeCostInfo() {
      this.isShowCostInfo = false;
    },
    /* MORE VIEWS 이미지 팝업 보이기 */
    showCarouselInfo(idx) {
      const img = new Image();
      img.src = this.moreViews[idx];
      this.moreViewsModalHeight = img.width - img.height > 0? '56vw' : '80vh';
      this.moreViewsSwitched = [];
      for(var i=0; i < this.moreViews.length; i++) {
        this.moreViewsSwitched[i] = this.moreViews[idx++];
        if(idx == this.moreViews.length) {
          idx = 0;
        }
      }
      this.showCarouselIndex = 0;
      this.isShowCarouselInfo = true;
    },
    /* MORE VIEWS 이미지 팝업 감추기 */
    closeCarouselInfo() {
      this.isShowCarouselInfo = false;
    }
  },
};
</script>

<style scoped>
.title {
  height: unset;
}
</style>