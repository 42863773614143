<template>
    <div class="d-flex">
        <LNB :user="user"/>
        <div class="ca_main">
            <div class="x_div_center">
                <div class="pg_stage">

                    <div class="x_div_center pt-5">
						<div class="" style="width:75%"><span class="fkr-set1 fs-16" style="color:#333333">{{prjNm}}</span></div>
						<div class="text-end" style="width:25%"><span class="fkr-set2" style="color:#484848">{{prcNm}} > <span class="fw-bold">개요</span></span></div>
					</div>

                    <div style="height:5px; background-color:#dccccc; border-radius:10rem"></div>

                    <div style="height:30px"></div>

                    <div v-if="isBidInited || isMatchInited">
                        <div class="pg_m_set" v-if="!prcId">
                            <div class="x_div_center"><div><i class='h3 fa fa-spinner fa-spin'></i></div></div>
                        </div>

                        <div v-else>
                            <div class="pg_m_set2">
                                <div class="pg_d_set2a" v-if="fnlPath">
                                    <div class="x_div_center py-3" style="width: inherit; height: auto">
                                        <iframe v-if="mimeType == 'pdf' || mimeType == 'PDF'" :src="'https://s3.' + s3region + '.amazonaws.com/' + s3bucket + '/' + fnlPath" style="width: inherit; height: 250px"></iframe>
                                        <img v-else style="width: inherit; height: auto; padding: 10px" :src="'https://s3.' + s3region + '.amazonaws.com/' + s3bucket + '/' + fnlPath">
                                    </div>
                                    <div class="">
                                        <div class="x_div_center py-2" v-if="user.info.usrRole != 'SL'"><button @click="moveToContract()" class="fkr-set2" style="width:120px; height:37px; background-color:#eee7e7; border:1px solid #4e0000; border-radius:4px; color:#4e0000">계약 상세 보기</button></div>
                                    </div>
                                </div>
                                <div class="pg_d_set2b">

                                    <div class="table-responsive" style="border:1px solid #c9c9c9; border-radius:8px">
                                        <table class="table-borderless w-100">
                                        <tr style="height:55px; background-color:#eaeaea; border-bottom:1px solid #c9c9c9">
                                            <td colspan="3" class="text-center"><span class="fa-kor-Noto" style="font-weight:500; font-size:14pt">건축주</span></td>
                                        </tr>
                                        <tr style="height:55px; border-bottom:1px solid #c9c9c9">
                                            <td class="text-center br_0" width="33%"><span class="fkr-set2" style="font-size:12pt">이름</span></td>
                                            <td class="text-center br_0" width="34%"><span class="fkr-set2" style="font-size:12pt">연락처</span></td>
                                            <td class="text-center" width="33%"><span class="fkr-set2" style="font-size:12pt">이메일</span></td>
                                        </tr>
                                        <tr style="height:55px">
                                            <td class="text-center br_0"><span class="fkr-set2" style="font-size:12pt">{{ownrNm}}</span></td>
                                            <td class="text-center br_0"><span class="fkr-set2" style="font-size:12pt">{{ownrPhone}}</span></td>
                                            <td class="text-center"><span class="fkr-set2" style="font-size:1rem">{{ownrEmail}}</span></td>
                                        </tr>
                                        </table>
                                    </div>

                                    <div style="height:20px"></div>

                                    <div class="table-responsive" style="border:1px solid #c9c9c9; border-radius:8px">
                                        <table class="table-borderless w-100">
                                        <tr style="height:55px; background-color:#eaeaea; border-bottom:1px solid #c9c9c9">
                                            <td colspan="3" class="text-center"><span class="fa-kor-Noto" style="font-weight:500; font-size:14pt">{{prcNm}}사</span></td>
                                        </tr>
                                        <tr style="height:55px; border-bottom:1px solid #c9c9c9">
                                            <td class="text-center br_0" width="33%"><span class="fkr-set2" style="font-size:12pt">회사명</span></td>
                                            <td class="text-center br_0" width="34%"><span class="fkr-set2" style="font-size:12pt">담당자연락처</span></td>
                                            <td class="text-center" width="33%"><span class="fkr-set2" style="font-size:12pt">이메일</span></td>
                                        </tr>
                                        <tr style="height:55px">
                                            <td class="text-center br_0"><span class="fkr-set2" style="font-size:12pt">{{prtnerNm}}</span></td>
                                            <td class="text-center br_0"><span class="fkr-set2" style="font-size:12pt">{{prtnerPhone}}</span></td>
                                            <td class="text-center"><span class="fkr-set2" style="font-size:12pt">{{prtnerEmail}}</span></td>
                                        </tr>
                                        </table>
                                    </div>

                                </div>
                            </div>

                            <div style="height:20px"></div>

                            <div v-if="prtnerEmail">

                                <div class="mib_box_c w-100 x_div_center pt-3"><div style="border-bottom:2px solid #c9c9c9"><h3 class="fkr-set1 fs-11">비용지급현황</h3></div></div>

                                <div class="pg_m_set" v-if="fetching" style="margin-top: 20px">
                                    <div class="x_div_center"><div><i class='h3 fa fa-spinner fa-spin'></i></div></div>
                                </div>
                                <div class="mib_box_c w-100 pt-3" v-else>
                                    <div class="x_div_center" style="height:55px; border-top-left-radius:.3rem; border-top-right-radius:.3rem; background-color:#eaeaea">
                                        <div class="x_div_center" style="width:24%"><span class="fkr-set1">내용</span></div>
                                        <div class="x_div_center" style="width:14%"><span class="fkr-set1">비율</span></div>
                                        <div class="x_div_center" style="width:19%"><span class="fkr-set1">금액</span></div>
                                        <div class="x_div_center" style="width:21%"><span class="fkr-set1">{{ (user.info.usrRole == 'OW' || user.info.usrRole == 'SL')? '입금 요청일' :'대금 요청일' }}</span></div>
                                        <div class="x_div_center" style="width:13%"><span class="fkr-set1">영수증</span></div>
                                        <div class="x_div_center" style="width:5%"><span class="fkr-set1"></span></div>
                                    </div>

                                    <div class="x_div_center ls_bg1" style="height:55px" v-for="(item, index) in expenses" :key="index">
                                        <div class="x_div_center px-1" style="width:24%"><span class="fkr-set2">{{ item.expnsNm }}</span></div>
                                        <div class="x_div_center px-1" style="width:14%"><span class="fkr-set2">{{ item.rates }}%</span></div>
                                        <div class="x_div_center px-1" style="width:19%"><span class="fkr-set2">{{ item.amounts? item.amounts.toLocaleString() : '' }}</span></div>

                                        <div class="x_div_center px-1" style="width:21%" v-if="user.info.usrRole == 'OW' || user.info.usrRole == 'SL'"><span class="fkr-set2">{{ renderDateForm(item.dpstRqstDt) }}</span></div>
                                        <div class="x_div_center px-1" style="width:21%" v-else>
                                            <button v-if="index != 0 && item.amounts && !item.pymntRqstDt" @click="requestPayment(index)" class="fkr-set2 fs-085" style="width:120px; height:37px; border:0; background-color:#4e0000; color:#fff; border-radius:4px">대금 요청<i v-if="requesting" style="margin-left: 10px" class='fa fa-spinner fa-spin'></i></button>
                                            <span v-else class="fkr-set2">{{ renderDateForm(item.pymntRqstDt) }}</span>
                                        </div>

                                        <!-- <div class="x_div_center px-1" style="width:21%" v-if="user.info.usrRole == 'OW' || user.info.usrRole == 'SL'">
                                            <button v-if="user.info.usrRole == 'OW' && index != 0 && item.apprvlRqstDt && !item.apprvlPymntDt" @click="approvePayment(index)" class="fkr-set2 fs-085" style="width:120px; height:37px; border:0; background-color:#4e0000; color:#fff; border-radius:4px">지급 승인</button>
                                            <span v-else class="fkr-set2">{{ renderDateForm(item.apprvlPymntDt) }}</span>
                                        </div>
                                        <div class="x_div_center px-1" style="width:21%" v-else><span class="fkr-set2">{{ renderDateForm(item.pymntDt) }}</span></div> -->

                                        <div class="x_div_center px-1" style="width:13%" v-if="user.info.usrRole == 'OW' || user.info.usrRole == 'SL'">
                                            <span class="fkr-set2" style="width: 100%; display: block; text-overflow: ellipsis; overflow: hidden; text-align: center;"><a :href="item.fileLink" :download="item.orgNm"> <i class="fas fa-download" v-show="isMobile && item.orgNm"/> {{ isMobile? '' : item.orgNm }} </a></span>
                                        </div>
                                        <div class="x_div_center px-1" style="width:13%" v-else>
                                            <input v-if="!item.orgNm" v-show="!isMobile" type="file" :id="'file' + index" @change="fileHandling($event, index)" class="form-control fkr-set2 fc-w5 text-center" style="width:100%; height:36px; border-radius:4px; background-color:#f6f2f2; border:1px solid #4e0000" placeholder="">
                                            <label :for="'file' + index" v-if="!item.orgNm" v-show="isMobile"><span class="fkr-set2"><i class="fas fa-upload"/></span></label>
                                            <span v-if="item.orgNm" class="fkr-set2" style="width: 100%; display: block; text-overflow: ellipsis; overflow: hidden; text-align: center;"><a :href="item.fileLink" :download="item.orgNm"> <i class="fas fa-download" v-show="isMobile"/> {{ isMobile? '' : item.orgNm }} </a></span>
                                        </div>
                                        <div class="x_div_center px-1" style="width:5%">
                                            <div v-if="!(user.info.usrRole == 'OW' || user.info.usrRole == 'SL') && item.orgNm" class="x_div_center px-2"><a href="javascript:void(0)" class="rm_frm" @click="removeReceipt(index)"><i class="fa-solid fa-circle-minus fs-10" style="color:#C00"></i></a></div>
                                            <i v-if="!(user.info.usrRole == 'OW' || user.info.usrRole == 'SL') && isMobile && item.isFileUplodead" class="fas fa-check"/>
                                        </div>
                                    </div>
                                    
                                    <!-- <div id="v_frm"></div> -->

                                    <!-- <div class="x_div_center py-2"><a href="#@" id="add_frm"><i class="fa-solid fa-circle-plus fs-15"></i></a></div> -->
                                </div>
                                <div class="x_div_end" v-if="isMobile"><span class="fkr-set2">※금액 단위는 1,000원 입니다.</span></div>
                            </div>
                            <div v-else>
                                <div class="">
                                    <div class="x_div_center py-2" v-if="isBidInited"><button @click="moveToBid()" class="fkr-set2 fs-095" style="width:120px; height:37px; border:0; background-color:#4e0000; color:#fff; border-radius:4px">비딩 바로가기<i v-if="requesting" style="margin-left: 10px" class='fa fa-spinner fa-spin'></i></button></div>
                                    <div class="mib_box_c w-100 x_div_center pt-3" v-if="isMatchInited"><div style="border-bottom:2px solid #c9c9c9"><h3 class="fkr-set1 fs-11">{{prcNm}}사 선정중</h3></div></div>
                                </div>
                            </div>

                            <div class="" v-if="user.info.usrRole != 'OW' && user.info.usrRole != 'SL'">
                                <div class="x_div_end py-2"><button @click="saveReceipts()" class="fkr-set2 fs-095" style="width:120px; height:37px; border:0; background-color:#4e0000; color:#fff; border-radius:4px">저장<i v-if="submitting" style="margin-left: 10px" class='fa fa-spinner fa-spin'></i></button></div>
                            </div>

                        </div>
                        <div style="height:50px"></div>
                    </div>
                    <div v-else>
                        <div class="pg_m_set" v-if="!dsgnRprsnttvImgPath">
                            <div class="x_div_center"><div><i class='h3 fa fa-spinner fa-spin'></i></div></div>
                        </div>
                        <div v-else>
                            <div class="pg_a_set3">
                                <div class="pg_a_set3a">
                                    <div class="card x_div_center" style="width:100%; border:0px solid #c9c9c9; border-radius:8px;">
                                        <div class="x_div_center" style="width:100%;"><div class="picture"></div></div>
                                    </div>
                                </div>
                                <div class="pg_a_set3c"></div>
                                <div class="pg_a_set3b">
                                    <div class="card x_div_center" style="width:100%; border:0px solid #c9c9c9; border-radius:8px">
                                        <div class="x_div_center" style="width:100%;"><div class="picture"></div></div>
                                    </div>
                                </div>
                            </div>
                            <div class="w-100 x_div_center pt-5">
                                <h3 class="fkr-set1 fs-11" v-if="this.$route.params.prcCd == 'DS'">
                                    설계가 필요하신 경우 공개 입찰을 통해 계약 대상 건축사를 선정하기 위해 '입찰 진행'을 클릭하세요.<br/>
                                    Build Market 골라보기에서 모델을 선택하셨거나 설계과정이 불필요하신 경우 '매칭 신청'을 클릭하세요.<br/>
                                </h3>
                                <h3 class="fkr-set1 fs-11" v-else-if="this.$route.params.prcCd == 'MA'">
                                    제조 진행을 위해서는 제조사 매칭 신청이 필요합니다. 진행 하시겠습니까?
                                </h3>
                                <h3 class="fkr-set1 fs-11" v-else-if="this.$route.params.prcCd == 'CN' && isGeneralConstructorCase">
                                    시공 진행을 위해서는 종합건설사 입찰 과정이 필요합니다. 진행 하시겠습니까?
                                </h3>
                                <h3 class="fkr-set1 fs-11" v-else-if="this.$route.params.prcCd == 'CN' && !isGeneralConstructorCase">
                                    60평 이하의 면적 혹은 특수 계약의 경우 종합건설사의 시공 참여가 필수는 아닙니다.<br/>
                                    따라서 시공 진행 시 고객님께서 건설사 입찰을 통해 선정하실 수도 있지만 M3에서 파트너 시공사를 직접 매칭해드리는 방법 또한 가능합니다.<br/>
                                    어떤 과정으로 진행하시겠습니까?
                                </h3>
                                <h3 class="fkr-set1 fs-11" v-else-if="prcTree && (prcTree.processList.indexOf(this.$route.params.prcCd) >= 0 || prcTree.prcScndryList.indexOf(this.$route.params.prcCd))">
                                    {{prcNm}} 단계 진행을 위해서는 {{prcNm}}사 입찰 과정이 필요합니다. 진행 하시겠습니까?
                                </h3>
                            </div>
                            <div class="x_div_center">
                                <div class="py-2 pe-1" v-if="this.$route.params.prcCd != 'MA' && (prcTree.processList.indexOf(this.$route.params.prcCd) >= 0 || prcTree.prcScndryList.indexOf(this.$route.params.prcCd))"><button @click="moveToBid()" class="fkr-set2 fs-095" style="width:120px; height:37px; border:0; background-color:#4e0000; color:#fff; border-radius:4px">입찰 진행<i v-if="requesting" style="margin-left: 10px" class='fa fa-spinner fa-spin'></i></button></div>
                                <div class="py-2 pe-1" v-if="this.$route.params.prcCd == 'MA' || (this.$route.params.prcCd == 'CN' && !isGeneralConstructorCase)"><button @click="submitRequest()" :disabled="submitting" class="fkr-set2 fs-095" style="width:120px; height:37px; border:0; background-color:#4e0000; color:#fff; border-radius:4px">매칭 신청<i v-if="submitting" style="margin-left: 10px" class='fa fa-spinner fa-spin'></i></button></div>
                                <div class="py-2 pe-1" v-if="this.$route.params.prcCd == 'DS'"><button @click="openModal()" :disabled="submitting" class="fkr-set2 fs-095" style="width:120px; height:37px; border:0; background-color:#4e0000; color:#fff; border-radius:4px">매칭 신청</button></div>
                            </div>
                        </div>
						<div style="height:20px"></div>
                    </div>

                    <div id="v-view" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style="z-index:10001;">
						<div class="modal-dialog modal-lg" role="document">
							<div class="modal-content">

								<div class="w-100">
									<div class="x_div_center w-100" style="background-color:#dccccc; border-top-left-radius:8px; border-top-right-radius:8px">
										<div class="w-100 p-3 ps-4"><span class="fkr-set1 fs-13">건축설계사 매칭 신청</span></div>
										<div class="text-end pe-4" style="width:100px"><a href="#@" @click="closeModal()" style="color:#4e0000" data-dismiss="modal"><i class="fa-solid fa-xmark fs-15"></i></a></div>
									</div>

									<div class="x_div_center py-2 px-4">
										<div class="w-100">
                                            <div style="display: flex">
                                                <div class="mib_a2 x_div_center"><div style="width:6px; height:6px; background-color:#e60; border-radius:50%; margin-top:8px"></div></div>
                                                <div class="mib_a3" style="padding-top: 15px"><h3 class="fkr-set1 fc-w6">내정하신 건축설계사의 정보를 최대한 입력해주세요.</h3></div>
                                            </div>

                                            <div class="mib_box_a py-3">
                                                <div class="mib_a1 text-end"><span class="fkr-set1">업체명</span></div>
                                                <div class="mib_a2 x_div_center"></div>
                                                <div class="mib_a3"><input type="text" class="form-control fkr-set2" style="width:180px; height:36px" placeholder="이름을 입력하세요" v-model="nominatedCompanyName"></div>
                                            </div>

											<div class="mib_box_a py-3">
                                                <div class="mib_a1 text-end"><span class="fkr-set1">대표자 이름</span></div>
                                                <div class="mib_a2 x_div_center"></div>
                                                <div class="mib_a3"><input type="text" class="form-control fkr-set2" style="width:180px; height:36px" placeholder="이름을 입력하세요" v-model="nominatedChiefName"></div>
                                            </div>

                                            <div class="mib_box_a py-3">
                                                <div class="mib_a1 text-end"><span class="fkr-set1">전화번호</span></div>
                                                <div class="mib_a2 x_div_center"></div>
                                                <div class="mib_a3"><input type="text" class="form-control fkr-set2" style="width:180px; height:36px" placeholder="전화번호를 입력하세요" v-model="nominatedPhone"></div>
                                            </div>

                                            <div class="mib_box_a py-3">
                                                <div class="mib_a1 text-end"><span class="fkr-set1">이메일</span></div>
                                                <div class="mib_a2 x_div_center"></div>
                                                <div class="mib_a3">

                                                    <div class="mib_a3s">
                                                        <div class="py-1"><input type="text" class="form-control ff-eng1" style="width:180px; height:36px" placeholder="E-mail" v-model="nominatedEmailHead"></div>
                                                        <div class="x_div_center py-1 text-center" style="width:30px"><span class="ff-eng1 fs-09 fw-bold fc-w3">@</span></div>
                                                        <div class="py-1 pe-2"><input type="text" class="form-control ff-eng1" style="width:180px; height:36px" placeholder="" v-model="nominatedEmailTail"></div>
                                                        <div class="mib_a3s_d py-1">
                                                            <select class="custom-select ff-eng1 fs-085" style="width:180px; height:36px" v-model="nominatedEmailTail">
                                                                <option value="">직접입력</option>
                                                                <option value="naver.com">naver.com</option>
                                                                <option value="gmail.com">gmail.com</option>
                                                                <option value="daum.net">daum.net</option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="mib_box_a py-3">
                                                <div class="mib_a1 text-end"><span class="fkr-set1">홈페이지</span></div>
                                                <div class="mib_a2 x_div_center"></div>
                                                <div class="mib_a3"><input type="text" class="form-control fkr-set2" style="width:180px; height:36px" placeholder="https://" v-model="nominatedHomepage"></div>
                                            </div>
										</div>
									</div>

									<div class="x_div_center py-4">
										<div class="px-2"><button @click="closeModal()" class="x_div_center" style="width:90px; height:30px; border:0; background-color:#fff; border:1px solid #4e0000; border-radius:4px; color:#4e0000" data-dismiss="modal"><span class="fkr-set1">닫기</span></button></div>
                                        <div class="px-2"><button @click="submitRequest()" class="x_div_center" :disabled="submitting" style="width:90px; height:30px; border:0; background-color:#4e0000; border-radius:4px"><span class="fa-kor-Noto fs-085 fc-w1">신청 등록<i v-if="submitting" style="margin-left: 10px" class='fa fa-spinner fa-spin'></i></span></button></div>
									</div>

									<div style="height:20px"></div>

								</div>
							</div>
						</div>
					</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import LNB from '@/components/LNB.vue'
import Auth from '@/api/auth'
import Api from '@/api'

export default {
    name: 'DesignSummary',
    components: {
		LNB
	},
    props: {
        user: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            prjId: null,
            prjNm: '',
            prcId: null,
            prcNm: '',
            prcTree: null,
            fetching: false,
            submitting: false,
            requesting: false,
            isBidInited: false,
            isMatchInited: false,
            nominatedChiefName: '',
            nominatedCompanyName: '',
            nominatedPhone: '',
            nominatedEmailHead: '',
            nominatedEmailTail: '',
            nominatedHomepage: '',
            addr: '',
            prtnerEmail: '',
            prtnerNm: '',
            prtnerPhone: '',
            fnlPath: '',
            ownrEmail: '',
            ownrNm: '',
            ownrPhone: '',
            mimeType: '',
            expenses: [],
            dltExpnsTypeList:[],
            multipartFileList: [],
            rcptDtoList: [],
            isGeneralConstructorCase: false,
            dsgnRprsnttvImgPath: '',
            dsgnRprsnttvImgPath2: '',
            s3region: process.env.VUE_APP_S3_REGION,
            s3bucket: process.env.VUE_APP_S3_BUCKET,
            isMobile: false
        }
    },
    async created() {
        if(this.$route.query.prjId){
            var rtnval = await Api.get('/api/v1/process/list/prc/tree?prjId=' + this.$route.query.prjId);
            if(rtnval.result) {
                sessionStorage.setItem('prcTree', JSON.stringify(rtnval.data));
                sessionStorage.setItem('prjId', this.$route.query.prjId);
                sessionStorage.setItem('prjNm', rtnval.data.prjNm);
                var rtnvalue = await Api.get('/api/v1/user/list/authorized/prc?usrRole=' + this.user.info.usrRole);
                if(rtnvalue.result) {
                    var accessiblePrc = [];
                    rtnvalue.data.processes.forEach((prc) => {
                        accessiblePrc.push(prc.code);
                    })
                    sessionStorage.setItem('accessiblePrc', JSON.stringify(accessiblePrc));
                    location.href = location.href.split("?")[0];
                }
                else {
                    alert(rtnvalue.errormsg)
                }
            }
            else {
                alert(rtnval.errormsg)
            }
        }
        else {
            this.prjId = parseInt(sessionStorage.getItem('prjId'));
            this.prjNm = sessionStorage.getItem('prjNm');
            this.prcTree = JSON.parse(sessionStorage.getItem('prcTree'));
            
            this.prcTree.processList.forEach((prc) => {
                if(prc.code == this.$route.params.prcCd) {
                    this.prcNm = prc.name;
                }
            })
    
            this.prcTree.prcScndryList.forEach((prc) => {
                if(prc.code == this.$route.params.prcCd) {
                    this.prcNm = prc.name;
                }
            })
        }
        
        
        if(this.prjId == 'null') {
            alert('My Projects 페이지에서 프로젝트를 선택하셔야 합니다.');
            location.href = '/project/dashboard'
        }
    },
    async mounted() {
        if(!await Auth.isLogedIn()){
           if(this.$route.query.prjId){
               this.$router.push({ path: '/member/login', query: { fromTalk: encodeURIComponent("/project" + location.href.split("/project")[1]) } });
           }
           else {
               location.href = '/member/login'
           }
        }

        this.isMobile = window.innerWidth < 768;
        var rtnval = await Api.post('/api/v1/process/get', {prjId: this.prjId, prcCd: this.$route.params.prcCd});
        if(rtnval.result) {
            this.prcId = rtnval.data.prcId;
            this.isBidInited = rtnval.data.bddngCrtDt && rtnval.data.bddngTyp == 1 ? true : false;
            this.isMatchInited = rtnval.data.bddngCrtDt && rtnval.data.bddngTyp == 0 ? true : false;
            if(this.prcId) {
                this.ownrEmail = rtnval.data.ownrInfo.email;
                this.ownrNm = rtnval.data.ownrInfo.fullName;
                const ownrPhoneRaw = rtnval.data.ownrInfo.phone;
                if(ownrPhoneRaw.length == 10) {
                    this.ownrPhone = ownrPhoneRaw.substring(0,3) + '-' + ownrPhoneRaw.substring(3,6) + '-' + ownrPhoneRaw.substring(6);
                }
                else{
                    this.ownrPhone = ownrPhoneRaw.substring(0,3) + '-' + ownrPhoneRaw.substring(3,7) + '-' + ownrPhoneRaw.substring(7);
                }
    
                if(rtnval.data.prtnrInfo) {
                    this.prtnerEmail = rtnval.data.prtnrInfo.email;
                    this.prtnerNm = rtnval.data.prtnrInfo.fullName;
                    const prtnerPhoneRaw = rtnval.data.prtnrInfo.phone;
                    
                    if(prtnerPhoneRaw){
                        if(prtnerPhoneRaw.length == 10) {
                            this.prtnerPhone = prtnerPhoneRaw.substring(0,3) + '-' + prtnerPhoneRaw.substring(3,6) + '-' + prtnerPhoneRaw.substring(6);
                        }
                        else{
                            this.prtnerPhone = prtnerPhoneRaw.substring(0,3) + '-' + prtnerPhoneRaw.substring(3,7) + '-' + prtnerPhoneRaw.substring(7);
                        }
                    }
                }
    
                if(rtnval.data.attachFiles) {
                    rtnval.data.attachFiles.forEach((attch) => {
                        if(attch.prcAttchFlCtgry.code == 'CT') {
                            this.fnlPath = attch.attchWebPath;
                            var tokens = this.fnlPath.split('.');
                            this.mimeType = tokens[tokens.length-1];
                        }
                    })
                }
            }
        }
        else {
            alert(rtnval.errormsg)
        }

        this.fetching = true;
        rtnval = await Api.get('/api/v1/project/find/prj/dtl/smmry?prjId=' + this.prjId);
        this.fetching = false;
        if(rtnval.result) {
            this.addr = rtnval.data.prj.addr;
            this.dsgnRprsnttvImgPath = rtnval.data.dsgnRprsnttvImgPath[0];
            this.dsgnRprsnttvImgPath2 = rtnval.data.dsgnRprsnttvImgPath[1];
            this.isGeneralConstructorCase = rtnval.data.prj.totalGroundArea / 3.3 >= 60 ? true : false;
        }
        else {
            alert(rtnval.errormsg)
        }

        if(!this.isBidInited && !this.isMatchInited) {
            const intervalId = window.setInterval(() => {
                console.log(window.$('.picture').html());
                if(window.$('.picture').html() != undefined) {
                    window.$('.picture:eq(0)').css('background-image', 'url(https://s3.' + this.s3region + '.amazonaws.com/' + this.s3bucket + '/' + this.fixedEncodeURIComponent(this.dsgnRprsnttvImgPath) + ')');
                    window.$('.picture:eq(1)').css('background-image', 'url(https://s3.' + this.s3region + '.amazonaws.com/' + this.s3bucket + '/' + this.fixedEncodeURIComponent(this.dsgnRprsnttvImgPath2) + ')');
                    window.clearInterval(intervalId);
                }
            }, 100)
        }

        if(this.prcId) {
            this.fetchExpenseInfo();
        }
        
    },
    methods: {
        async fetchExpenseInfo() {
            this.fetching = true;
            const rtnval = await Api.get('/api/v1/payment/get?prcId=' + this.prcId);
            if(rtnval.result){
                this.expenses = [];
                this.multipartFileList = [];
                this.rcptDtoList = [];
                rtnval.data.pymntList = rtnval.data.pymntList.sort((a, b) => { return a.pymntType - b.pymntType })
                rtnval.data.pymntList.forEach((item) => {
                    if(item.pymntType == 0) {
                        item.expnsNm = '선급금';
                        item.apprvlPymntDt = '-';
                    }
                    else if(item.pymntType +1 == rtnval.data.pymntList.length) {
                        item.expnsNm = '잔금';
                    }
                    else {
                        item.expnsNm = item.pymntType + '차 중도금'
                    }

                    item.fileLink = '';
                    if(item.attchImg){
                        var tokens = item.orgNm.split('.');
                        var mimeType = tokens[tokens.length-1].toLowerCase();
                        var fileType = mimeType == 'pdf'? 'application/' + mimeType : 'image/' + mimeType;
        
                        const byteCharacters = atob(item.attchImg);
                        const byteNumbers = new Array(byteCharacters.length);
                        for (let i = 0; i < byteCharacters.length; i++) {
                            byteNumbers[i] = byteCharacters.charCodeAt(i);
                        }
                        const byteArray = new Uint8Array(byteNumbers);
                        const blob = new Blob([byteArray], {type: fileType});
                        item.fileLink = URL.createObjectURL(blob);
                    }

                    this.multipartFileList.push(null);
                    this.rcptDtoList.push({pymntType: item.pymntType, orgNm: item.orgNm});
                    item.isFileUplodead = false;
                    if(this.isMobile && item.amounts){
                        item.amounts = Number((item.amounts/1000).toFixed(0))
                    }
                    this.expenses.push(item);
                })
            }
            else {
                alert(rtnval.errormsg)
            }
            this.fetching = false;
        },
        renderDateForm(datetime) {
            if (this.isMobile) {
                return datetime? datetime.split('T')[0].substring(5) : '';
            }
            else {
                return datetime? datetime.split('T')[0] : '';
            }
        },
        fixedEncodeURIComponent(str) {
			return encodeURIComponent(str).replace(/[!'()]/g, function(c) {
					return '%' + c.charCodeAt(0).toString(16);
				});
		},
        async approvePayment(index) {
            ////////////////// legacy //////////////////////
            const rtnval = await Api.post('/api/v1/dsgn/sm/save/dsgn/apprvl/pymnt', {prjId: this.prjId, usrId: this.user.id, dsgnId: this.prjInfo.dsgnId, expnsType: index});
            if(rtnval.result) {
                this.fetchExpenseInfo();
            }
            else {
                alert(rtnval.errormsg)
            }
            ////////////////////////////////////////////////
        },
        async requestPayment(index) {
            if(!this.expenses[index-1].orgNm) {
                alert("이전 단계 대금지급 영수증 업로드를 완료해주세요.");
                return;    
            }

            var request = {
                pymntList: []
            }

            const formData = new FormData();
            formData.append("attchmntList", null);
            request.pymntList.push({
                pymntId: this.expenses[index].pymntId,
                pymntRqstDt: new Date().toISOString(),
                editing: false
            })
            formData.append(
                "request",
                new Blob([JSON.stringify(request)], { type: "application/json" })
            );
            this.requesting = true;
            const rtnval = await Api.post('/api/v1/payment/set', formData);
            if(rtnval.result) {
                await this.fetchExpenseInfo();
            }
            else {
                alert(rtnval.errormsg)
            }
            this.requesting = false;
        },
        fileHandling(e, index) {
            this.multipartFileList[index] = e.target.files[0];
            this.rcptDtoList[index].orgNm = e.target.files[0].name;
            this.expenses[index].isFileUplodead = true;
        },
        removeReceipt(index) {
            if(this.dltExpnsTypeList.indexOf(index) < 0) {
                this.dltExpnsTypeList.push(index);
            }
            this.expenses[index].orgNm = null;
        },
        async saveReceipts() {
            var request = {
                pymntList: []
            }

            const formData = new FormData();
            this.multipartFileList.forEach((item, index) => {
                if(item) {
                    formData.append("attchmntList", item);
                    request.pymntList.push({
                        pymntId: this.expenses[index].pymntId,
                        editing: true,
                        orgNm: this.rcptDtoList[index].orgNm
                    });
                }
            })
            formData.append(
                "request",
                new Blob([JSON.stringify(request)], { type: "application/json" })
            );

            if(request.pymntList.length == 0) {
                alert('업로드한 영수증이 없습니다.');
            }
            else {
                this.submitting = true;
                const rtnval = await Api.post('/api/v1/payment/set', formData);
                if(rtnval.result) {
                    await this.fetchExpenseInfo();
                } else {
                    alert(rtnval.errormsg)
                }
                this.submitting = false;
            }
        },
        async moveToBid() {
            if(['DS', 'DE', 'DH'].indexOf(this.$route.params.prcCd) >= 0) {
                if(!this.addr) {
                    alert('대지 위치를 입력하셔야 입찰을 진행하실 수 있습니다.');
                    return 1;
                }
                sessionStorage.setItem('prcCd', this.$route.params.prcCd);
                sessionStorage.setItem('prcId', this.prcId);
                location.href = '/project/bidding/customer/instruction'
            }
            else {
                this.requesting = true;
                const rtnval = await Api.get('/api/v1/project/find/prj/dtl/smmry?prjId=' + this.prjId);
                this.requesting = false;
                if(rtnval.result) {
                    if(rtnval.data.prcInfoDto[0].prcStpTypCd == 'DI' && rtnval.data.prcInfoDto[0].prcStts.indexOf("완료") >= 0) {
                        sessionStorage.setItem('prcCd', this.$route.params.prcCd);
                        sessionStorage.setItem('prcId', this.prcId);
                        location.href = '/project/bidding/customer/instruction'
                    }
                    else {
                        alert('설계 단계가 완료된 후에 입찰 진행 하실 수 있습니다.')
                    }
                }
                else {
                    alert(rtnval.errormsg)
                }
            }
        },
        moveToContract() {
            if(this.user.info.usrRole == 'OW') {
                sessionStorage.setItem('prcCd', this.$route.params.prcCd);
                sessionStorage.setItem('prcId', this.prcId);
                location.href = '/project/bidding/customer/contract'
            }
            else {
                location.href = '/project/bidding/partner/contract/' + this.prjId;
            }
        },
        openModal() {
            if(!this.addr) {
                alert('대지 위치를 입력하셔야 매칭을 신청하실 수 있습니다.');
                return 1;
            }
			window.$('#v-view').modal('show');
		},
		closeModal() {
			window.$('#v-view').modal('hide');
		},
        async submitRequest() {
            this.submitting = true;
            var rtnvalue = null;
            if(['DS', 'DE', 'DH'].indexOf(this.$route.params.prcCd) >= 0) {
                rtnvalue = await Api.post('/api/v1/process/set', {
                    prcCd: this.$route.params.prcCd,
                    prjId: this.prjId,
                    prcId: this.prcId,
                    bddngTyp: 0,
                    startBid: true
                });
                if(rtnvalue.result) {
                    rtnvalue = await Api.post('/api/v1/process/dsgn/match/noti', {
                        prjId: this.prjId,
                        nominatedChiefName: this.nominatedChiefName,
                        nominatedCompanyName: this.nominatedCompanyName,
                        nominatedPhone: this.nominatedPhone,
                        nominatedEmail: this.nominatedEmailHead + '@' + this.nominatedEmailTail,
                        nominatedHomepage: this.nominatedHomepage
                    });
                }
                else {
                    alert(rtnvalue.errormsg)
                }
            }
            else {
                const rtnval = await Api.get('/api/v1/project/find/prj/dtl/smmry?prjId=' + this.prjId);
                if(rtnval.result) {
                    if(rtnval.data.prcInfoDto[0].prcStpOrdNumMax +1 == this.prcTree.processList[0].prcStpList.length && rtnval.data.prcInfoDto[0].prcStts.indexOf("완료") >= 0) {
                        rtnvalue = await Api.post('/api/v1/process/set', {
                            prcCd: this.$route.params.prcCd,
                            prjId: this.prjId,
                            bddngTyp: 0,
                            startBid: true
                        });
                    }
                    else {
                        alert('설계 단계가 완료된 후에 매칭 신청을 하실 수 있습니다.')
                    }
                }
                else {
                    alert(rtnval.errormsg)
                }
            }
            this.submitting = false;
            if(rtnvalue && rtnvalue.result) {
                alert('매칭 신청이 정상적으로 접수되었습니다.');
                this.isMatchInited = true;
                window.$('#v-view').modal('hide');
            }
            else {
                alert(rtnvalue.errormsg)
            }
        }
    }
}
</script>

<style scoped>
.mib_box_a {
    width: auto;
}
.picture {
    width:100%;
    height: 326px;
    border-radius:8px;
    background-size: cover;
    background-position: 50% 50%;
}
</style>