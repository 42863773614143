<template>
  <div class="d-flex">
    <BudgetLNB/>
    <div class="ca_main">
      <div class="x_div_center">
        <div class="pg_stage" style="width:1920px !important">

          <div class="d-flex pt-5">
            <div style="flex:0 0 calc(100% - 200px) !important;"><span class="fa-Kor-Noto" style="font-weight:700; font-size:22pt; color:#333333">예산 관리</span></div>
            <div class="x_div_center"><div class="text-end" style="width:200px"><span class="fa-Kor-Noto" style="font-size:13pt; color:#484848"><span class="fw-bold"></span>{{ prjNm }}</span></div></div>
          </div>

          <div style="height:5px; background-color:#dccccc; border-radius:10rem"></div>

          <div style="height:20px"></div>

          <div class="w-100" style="display: flex;">
            <select class="custom-select ff-eng1 fs-085" v-model="budgetSeq" @change="switchSeq()" style="width:120px; height:37px;">
              <option value="0">예산</option>
              <option v-for="(item, i) in budgetList" :key="i" :value="item.seq">{{ item.seq + '차' }}</option>
            </select>
            <div class="ps-2" v-if="user.info.bdgtrLv == 1" style="margin:3px 0 0 10px;"><button class="fkr-set2 fs-095" @click="addSeq()" style="width:100px; height:32px; border:0; background-color:#4e0000; color:#fff; border-radius:4px;">차수 등록</button></div>
            <div class="x_div_center" v-if="user.info.bdgtrLv == 1" style="margin-left: auto;">
              <div class="x_div_end d-flex">
                <div class="ps-2" style="margin-right: 20px;"><button class="fkr-set2 fs-095" @click="openDesc(null);" style="width:120px; height:35px; background-color:#eee7e7; border:1px solid #4e0000; border-radius:4px; color:#4e0000;">예산항목 추가</button></div>
                <div v-if="budgetSeq > 0 " class="d-flex" style="width:245px; height:34px; border:1px solid #d6d6d6; border-radius:4px">
                  <div style="padding:0 10px 0 10px"><input type="text" id="ser_project" name="ser_project" v-model="searchWord"  class="form-control fa-kor-Noto" style="border:0; width:180px; height:30px; padding:0 .1rem" placeholder="자재의 품명을 검색하세요" ></div>
                  <div style="padding:5px 0 0 14px; border-left:1px solid #d6d6d6"><i class="fa-solid fa-magnifying-glass"></i></div>
                </div>
              </div>
              <div class="x_div_end">
                <div id="auto_frm" v-show="isSearch" class="p-2" style="display:none; position:absolute; top:220px; width:350px; background-color:#fff; border:1px solid #d6d6d6; border-radius:0 0 8px 8px;">
                  <div v-for="(item, index) in descListFiltered" :key="index" class="x_div_center ls_bg1">
                    <div class="w-100 text-start py-1 px-2">
                      <i class="fa-sharp fa-solid fa-magnifying-glass pe-2" style="color:#ddd"></i><i v-if="!item.mtrlItmDescId" class="fa fa-spinner fa-spin" style="color:#333"></i>
                      <a v-else href="javascript:void(0)" @click="addNewDescPrompt(item)">{{ item.mtrlItmDescId? (item.mtrlItm.mtrlItmNm + ' - ' + item.mtrlItm.stndrd + '(' + item.mtrlItm.unit) + ')' : '' }} <br>&nbsp;&nbsp;&nbsp;&nbsp;( {{ item.mtrlItmDescId? item.workType.typeNm : '' }} )</a>
                    </div>
                    <div class="text-center" style="width:40px"><i class="fa-solid fa-arrow-pointer" style="color:#ccc"></i></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <div style="height:20px"></div>

          <div class="table-responsive" style="border:1px solid #c9c9c9; border-radius:8px">
            <table class="table-borderless w-100">
              <tr style="height:55px; border-bottom:1px solid #c9c9c9">
                <td rowspan="2" class="text-center br_0" style="background-color:#eaeaea"><span class="ff-kor1" style="font-size:12pt"><strong>품명</strong></span></td>
                <td rowspan="2" class="text-center br_0" style="background-color:#eaeaea"><span class="ff-kor1" style="font-size:12pt"><strong>규격</strong></span></td>
                <td rowspan="2" class="text-center br_0" style="background-color:#eaeaea"><span class="ff-kor1" style="font-size:12pt"><strong>단위</strong></span></td>
                <td rowspan="2" class="text-center br_0" style="background-color:#eaeaea"><span class="ff-kor1" style="font-size:12pt"><strong>수량</strong></span></td>
                <td colspan="2" class="text-center br_0" style="background-color:#eaeaea"><span class="ff-kor1" style="font-size:12pt"><strong>재료비</strong></span></td>
                <td colspan="2" class="text-center br_0" style="background-color:#eaeaea"><span class="ff-kor1" style="font-size:12pt"><strong>노무비</strong></span></td>
                <td colspan="2" class="text-center br_0" style="background-color:#eaeaea"><span class="ff-kor1" style="font-size:12pt"><strong>경비</strong></span></td>
                <td colspan="2" class="text-center br_0" style="background-color:#eaeaea"><span class="ff-kor1" style="font-size:12pt"><strong>합계</strong></span></td>
                <td rowspan="2" class="text-center br_0" style="background-color:#eaeaea"><span class="ff-kor1" style="font-size:12pt"><strong>업체명</strong></span></td>
                <td rowspan="2" class="text-center br_0" style="background-color:#eaeaea"><span class="ff-kor1" style="font-size:12pt"><strong>비고</strong></span></td>
                <td rowspan="2" class="text-center" style="background-color:#eaeaea"><span class="ff-kor1" style="font-size:12pt"><strong>편집</strong></span></td>
              </tr>
              <tr style="height:55px; border-bottom:1px solid #c9c9c9">
                <td class="text-center br_0" style="background-color:#eaeaea;">
                  <span class="ff-kor1" style="font-size:12pt"><strong>단가</strong></span>
                </td>
                <td class="text-center br_0" style="background-color:#eaeaea;">
                  <span class="ff-kor1" style="font-size:12pt"><strong>금액</strong></span>
                </td>
                <td class="text-center br_0" style="background-color:#eaeaea;">
                  <span class="ff-kor1" style="font-size:12pt"><strong>단가</strong></span>
                </td>
                <td class="text-center br_0" style="background-color:#eaeaea;">
                  <span class="ff-kor1" style="font-size:12pt"><strong>금액</strong></span>
                </td>
                <td class="text-center br_0" style="background-color:#eaeaea;">
                  <span class="ff-kor1" style="font-size:12pt"><strong>단가</strong></span>
                </td>
                <td class="text-center br_0" style="background-color:#eaeaea;">
                  <span class="ff-kor1" style="font-size:12pt"><strong>금액</strong></span>
                </td>
                <td class="text-center br_0" style="background-color:#eaeaea;">
                  <span class="ff-kor1" style="font-size:12pt"><strong>단가</strong></span>
                </td>
                <td class="text-center br_0" style="background-color:#eaeaea;">
                  <span class="ff-kor1" style="font-size:12pt"><strong>금액</strong></span>
                </td>
              </tr>
              <template v-for="(large, i) in descListCurrentSeq">
                <tr :key="'large' + i" style="height:55px; border-bottom:1px solid #c9c9c9">
                  <td class="text" style="background-color:#c9c9c9; padding: 15px;"><span class="ff-kor1" style="font-size:12pt"> {{ large.typeOrder + ". " + large.typeNm }} </span></td>
                </tr>
                <template v-for="(med, k) in large.workTypeMd">
                  <tr :key="'med' + k" style="height:55px; border-bottom:1px solid #c9c9c9">
                    <td class="text" style="background-color:#eaeaea; padding: 15px;"><span class="ff-kor1" style="font-size:12pt"> {{ med.typeOrder + ". " + med.typeNm }} </span></td>
                  </tr>
                  <template v-for="(desc, j) in med.descList">
                    <tr :key="'desc' + j" style="height:55px; border-bottom:1px solid #c9c9c9">
                      <td class="text-center br_0"><span class="ff-kor1" style="font-size:12pt"> {{ desc.mtrlItm.mtrlItmNm }} </span></td>
                      <td class="text-center br_0"><span class="ff-kor1" style="font-size:12pt"> {{ desc.mtrlItm.stndrd }} </span></td>
                      <td class="text-center br_0"><span class="ff-kor1" style="font-size:12pt"> {{ desc.mtrlItm.unit }} </span></td>
                      <td class="text-center br_0">
                        <input v-if="user.info.bdgtrLv == 1" type="text" @input="bindExpense($event, i, k, j)" v-model="desc.qntty" class="form-control ff-eng1 text-center" style="width:60px; height:36px; display:block; margin: 0 auto;">
                        <span v-else class="ff-kor1" style="font-size:12pt"> {{ desc.qntty }} </span>
                      </td>
                      <td class="text-center br_0">
                        <input v-if="user.info.bdgtrLv == 1" type="text" @input="bindExpense($event, i, k, j)" v-model="desc.mtrlCst" class="form-control ff-eng1 text-center" style="width:100px; height:36px; display:block; margin: 0 auto;">
                        <span v-else class="ff-kor1" style="font-size:12pt"> {{ desc.mtrlCst }} </span>
                      </td>
                      <td class="text-center br_0">
                        <span class="ff-kor1" style="font-size:12pt"> {{ desc.qntty * desc.mtrlCst }} </span>
                      </td>
                      <td class="text-center br_0">
                        <input v-if="user.info.bdgtrLv == 1" type="text" @input="bindExpense($event, i, k, j)" v-model="desc.lbrCst" class="form-control ff-eng1 text-center" style="width:100px; height:36px; display:block; margin: 0 auto;">
                        <span v-else class="ff-kor1" style="font-size:12pt"> {{ desc.lbrCst }} </span>
                      </td>
                      <td class="text-center br_0">
                        <span class="ff-kor1" style="font-size:12pt"> {{ desc.qntty * desc.lbrCst }} </span>
                      </td>
                      <td class="text-center br_0">
                        <input v-if="user.info.bdgtrLv == 1" type="text" @input="bindExpense($event, i, k, j)" v-model="desc.othrCst" class="form-control ff-eng1 text-center" style="width:100px; height:36px; display:block; margin: 0 auto;">
                        <span v-else class="ff-kor1" style="font-size:12pt"> {{ desc.othrCst }} </span>
                      </td>
                      <td class="text-center br_0">
                        <span class="ff-kor1" style="font-size:12pt"> {{ desc.qntty * desc.othrCst }} </span>
                      </td>
                      <td class="text-center br_0">
                        <span class="ff-kor1" style="font-size:12pt"> {{ desc.mtrlCst + desc.lbrCst + desc.othrCst }} </span>
                      </td>
                      <td class="text-center br_0">
                        <span class="ff-kor1" style="font-size:12pt"> {{ desc.qntty * (desc.mtrlCst + desc.lbrCst + desc.othrCst) }} </span>
                      </td>
                      <td class="text-center br_0">
                        <input v-if="user.info.bdgtrLv == 1" type="text" v-model="desc.supplier" class="form-control ff-eng1 text-center" style="width:100px; height:36px; display:block; margin: 0 auto;">
                        <span v-else class="ff-kor1" style="font-size:12pt"> {{ desc.supplier }} </span>
                      </td>
                      <td class="text-center br_0">
                        <input v-if="user.info.bdgtrLv == 1" type="text" v-model="desc.etc" class="form-control ff-eng1 text-center" style="width:100px; height:36px; display:block; margin: 0 auto;">
                        <span class="ff-kor1" style="font-size:12pt"> {{ desc.etc }} </span>
                      </td>
                      <td class="text-center br_0">
                        <span class="ff-kor1" style="font-size:12pt"> <i class="fas fa-pen" style="cursor:pointer; margin-right:15px" @click="removeDesc(desc)"/><i class="fa fa-edit" style="cursor:pointer" @click="openDesc(desc)"/> </span>
                      </td>
                    </tr>
                  </template>
                </template>
              </template>
            </table>
          </div>

          <div style="height:20px"></div>

          <div class="w-100" style="display: flex;">
            <div class="ps-2" style="margin-left: auto; padding-left: 10px !important;" v-if="user.info.bdgtrLv == 1"><button class="fkr-set2 fs-095" style="width:100px; height:35px; border:0; background-color:#eee7e7; color:#4e0000; border-radius:4px; border:1px solid #4e0000;">일괄저장</button></div>
            <div class="ps-2" style="margin-left: 10px; padding-left: 10px !important;" v-if="user.info.bdgtrLv == 1"><button class="fkr-set2 fs-095" style="width:100px; height:35px; border:0; background-color:#4e0000; color:#fff; border-radius:4px;">결재상신</button></div>
            <div class="ps-2" style="margin-left: auto; padding-left: 10px !important;" v-if="user.info.bdgtrLv == 2"><button class="fkr-set2 fs-095" style="width:100px; height:35px; border:0; background-color:#4e0000; color:#fff; border-radius:4px;">승인</button></div>
          </div>

          <div style="height:50px"></div>

        </div>
      </div>

      <div id="addDesc" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style="z-index:10001;">
        <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">

            <div class="w-100">
              <div class="x_div_center w-100" style="background-color:#dccccc; border-top-left-radius:8px; border-top-right-radius:8px">
                <div class="w-100 p-3 ps-4"><span class="fkr-set1 fs-13">예산항목 추가</span></div>
                <div class="text-end pe-4" style="width:100px"><a href="#@" @click="closeModal()" style="color:#4e0000" data-dismiss="modal"><i class="fa-solid fa-xmark fs-15"></i></a></div>
              </div>

              <div class="x_div_center py-2 px-4">
                <div class="pg_m_set" v-if="fetching" style="padding: 20px">
                  <div class="x_div_center"><div><i class='h3 fa fa-spinner fa-spin'></i></div></div>
                </div>
                <div class="w-100" v-else>
                  <div class="mib_box_a py-3">
                    <div class="mib_a1 text-end"><span class="fkr-set1">자재 품목</span></div>
                    <div class="mib_a2 x_div_center"></div>
                    <div class="mib_a3">
                      <div class="x_div d-flex" style="justify-content: space-between;">
                        <div class="d-flex" style="width:245px; height:34px; border:1px solid #d6d6d6; border-radius:4px">
                          <div style="padding:0 10px 0 10px"><input type="text" id="ser_project" name="ser_project" placeholder="품목명" v-model="searchKeyword"  class="form-control fa-kor-Noto" style="border:0; width:180px; height:30px; padding:0 .1rem"></div>
                          <div style="padding:5px 0 0 14px; border-left:1px solid #d6d6d6"><i class="fa-solid fa-magnifying-glass"></i></div>
                        </div>
                      </div>
                      <div class="x_div">
                        <div id="auto_frm" v-show="isSearchMtrl" class="p-2" style="display:none; position:absolute; width:400px; background-color:#fff; border:1px solid #d6d6d6; border-radius:0 0 8px 8px;">
                          <div v-for="(item, index) in mtrlItmListFiltered" :key="index" class="x_div_center ls_bg1">
                            <div class="w-100 text-start py-1 px-2">
                              <i class="fa-sharp fa-solid fa-magnifying-glass pe-2" style="color:#ddd"></i><i v-if="!item.mtrlItmId" class="fa fa-spinner fa-spin" style="color:#333"></i>
                              <a href="javascript:void(0)" @click="foobar(item)">{{item.mtrlItmId? (item.mtrlItmNm + ' - ' + item.stndrd + (item.unit? '(' + item.unit + ')' : '')) : ''}}</a>
                            </div>
                            <div class="text-center" style="width:40px"><i class="fa-solid fa-arrow-pointer" style="color:#ccc"></i></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="mib_box_a py-3">
                      <div class="mib_a1 text-end"><span class="fkr-set1">대공종</span></div>
                      <div class="mib_a2 x_div_center"></div>
                      <div class="mib_a3">
                        <select class="custom-select ff-eng1 fs-085" v-model="workTypeLargeIdInput" @change="switchWorkTypeLg()" style="width:245px; height:37px;">
                          <option v-for="(item, i) in workTypeList" :key="'optionLg' + i" :value="item.workTypeLargeId">{{ item.typeNm }}</option>
                        </select>
                      </div>
                  </div>

                  <div class="mib_box_a py-3">
                      <div class="mib_a1 text-end"><span class="fkr-set1">중공종</span></div>
                      <div class="mib_a2 x_div_center"></div>
                      <div class="mib_a3">
                        <select class="custom-select ff-eng1 fs-085" v-model="workTypeMedIdInput" style="width:245px; height:37px;">
                          <option v-for="(item, i) in workTypeBucket" :key="'optionMd' + i" :value="item.workTypeMedId">{{ item.typeNm }}</option>
                        </select>
                      </div>
                  </div>
                </div>
              </div>

              <div class="x_div_center py-4">
                <div class="px-2"><button @click="closeModal()" class="x_div_center" style="width:90px; height:30px; border:0; background-color:#fff; border:1px solid #4e0000; border-radius:4px; color:#4e0000" data-dismiss="modal"><span class="fkr-set1">취소</span></button></div>
                <div class="px-2"><button @click="setMtrlItmDesc()" class="x_div_center" style="width:90px; height:30px; border:0; background-color:#4e0000; border-radius:4px"><span class="fa-kor-Noto fs-085 fc-w1">반영</span></button></div>
              </div>

              <div style="height:20px"></div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BudgetLNB from '@/components/BudgetLNB.vue'
import Auth from '@/api/auth'
import Api from '@/api'
export default {
  name: "BudgetExecuteList",
  components: {
		BudgetLNB
	},
  data(){
    return {
        fetching: false,
        // fetching: true,
        isSearch: false,
        searchWord: '',
        isSearchMtrl: false,
        searchKeyword: '',
        editMode: false,
        prjNm: '테스트 프로젝트',
        workTypeList:[],
        workTypeBucket:[],
        mtrlItmList: [],
        mtrlItmListFiltered: [],
        budgetList: [],
        budgetSeq: 0,
        descList: [],
        descListCurrentSeq: [],
        descListFiltered: [],
        mtrlItmIdInput: null,
        workTypeLargeIdInput: null,
        workTypeMedIdInput: null
    }
  },
  props: {
    user: {
        type: Object,
        default: null
    }
  },
  watch: {
    searchWord(val) {
      if(val) {
        this.isSearch = true;
        this.descListFiltered = this.descList.filter((v) => v.mtrlItm.mtrlItmNm.indexOf(val) >= 0);
      }
      else {
        this.isSearch = false;
      }
    },
    searchKeyword(val) {
      if(val) {
        this.isSearchMtrl = true;
        this.mtrlItmListFiltered = this.mtrlItmList.filter((v) => v.mtrlItmNm.indexOf(val) >= 0);
      }
      else {
        this.isSearchMtrl = false;
      }
    }
  },
  async mounted() {
    if(!await Auth.isLogedIn()){
      location.href = '/member/login'
    }

    if(!this.user.info.bdgtrLv) {
        alert('접근권한이 없습니다.');
        location.href = '/';
    }

    ///////////////////////////////////////
    this.budgetList = [{seq: 1}, {seq: 2}];
    
    this.workTypeBucket = [
      {workTypeMedId: 1, typeOrder: 1, typeNm: '골조공사'}, 
      {workTypeMedId: 2, typeOrder: 2, typeNm: '창호공사'}, 
      {workTypeMedId: 3, typeOrder: 3, typeNm: '외장마감공사'}, 
      {workTypeMedId: 4, typeOrder: 4, typeNm: '내장마감공사(인테리어)'}, 
      {workTypeMedId: 5, typeOrder: 5, typeNm: '가구공사(인테리어)'}, 
      {workTypeMedId: 6, typeOrder: 6, typeNm: '방수공사'}
    
    ]
    this.workTypeList = [
      {workTypeLargeId: 1, typeOrder: 1, typeNm: '건축공사', workTypeMed: this.workTypeBucket}, 
      {workTypeLargeId: 2, typeOrder: 2, typeNm: '전기통신공사', workTypeMed: []}, 
      {workTypeLargeId: 3, typeOrder: 3, typeNm: '기계설비공사', workTypeMed: []}, 
      {workTypeLargeId: 4, typeOrder: 4, typeNm: '기타공사', workTypeMed: []}, 
      {workTypeLargeId: 5, typeOrder: 5, typeNm: '설치준비공사', workTypeMed: []}
    ]

    this.mtrlItmList = [
      {
        mtrlItmId: 1,
        mtrlItmNm: 'ㄷ-CHANNEL',
        stndrd: '200*80*7.5*11',
        unit: 'kg',
        mtrlStyle: '11'
      },
      {
        mtrlItmId: 2,
        mtrlItmNm: '스터드75형 KS',
        stndrd: '2300',
        unit: 'EA',
        mtrlStyle: '11'
      },
      {
        mtrlItmId: 3,
        mtrlItmNm: '세탁기상부장',
        stndrd: '640*750*400',
        unit: 'EA',
        mtrlStyle: '12'
      },
      {
        mtrlItmId: 4,
        mtrlItmNm: '밸러스빔',
        stndrd: '마킹,용접',
        unit: '20',
        mtrlStyle: '21'
      },
      {
        mtrlItmId: 5,
        mtrlItmNm: '운반비 및 기타 장비',
        stndrd: '',
        unit: '',
        mtrlStyle: '22'
      },
    ]

    this.descList = [
      {
        mtrlItmDescId: 1,
        mtrlItm: {
          mtrlItmNm: 'ㄷ-CHANNEL',
          stndrd: '200*80*7.5*11',
          unit: 'kg'
        },
        workType:{
          typeNm: '골조공사'
        }
      },
      {
        mtrlItmDescId: 2,
        mtrlItm: {
          mtrlItmNm: '스터드75형 KS',
          stndrd: '2300',
          unit: 'EA'
        },
        workType:{
          typeNm: '골조공사'
        }
      },
      {
        mtrlItmDescId: 3,
        mtrlItm: {
          mtrlItmNm: '세탁기상부장',
          stndrd: '640*750*400',
          unit: 'EA'
        },
        workType:{
          typeNm: '가구공사(인테리어)'
        }
      },
      {
        mtrlItmDescId: 4,
        mtrlItm: {
          mtrlItmNm: '밸러스빔장비',
          stndrd: '마킹,용접',
          unit: '20'
        },
        workType:{
          typeNm: '설치준비공사'
        }
      },
      {
        mtrlItmDescId: 5,
        mtrlItm: {
          mtrlItmNm: '운반비 및 기타 장비',
          stndrd: '',
          unit: ''
        },
        workType:{
          typeNm: '외장마감공사'
        }
      }
    ]
    ///////////////////////////////////////
  },
  methods:{
    openDesc(descEdit) { 
      if(descEdit) {
        this.mtrlItmIdInput = descEdit.mtrlItm.mtrlItmId,
        this.workTypeLargeIdInput = descEdit.workType.workTypeLarge.workTypeLargeId,
        this.workTypeMedIdInput = descEdit.workType.workTypeMedId,
        this.editMode = true;
      }
      else {
        this.searchKeyword = '',
        this.mtrlItmIdInput = null,
        this.workTypeLargeIdInput = null,
        this.workTypeMedIdInput = null,
        this.editMode = false;
      }
      window.$('#addDesc').modal('show');
    },

    switchSeq() {
      this.descListCurrentSeq = [];
    },  

    foobar(item) {
      this.mtrlItmIdInput = item.mtrlItmId;
      this.searchKeyword = item.mtrlItmNm;
      setTimeout(() => {
        this.isSearchMtrl = false
      }, 100)
    },

    switchWorkTypeLg() {

    },

    setMtrlItmDesc() {
      if(!this.mtrlItmIdInput) {
        alert('자재품목을 선택하세요.');
        return;
      }
      if(!this.workTypeMedIdInput) {
        alert('공종분류를 선택하세요.');
        return;
      }
      
      var mtrlItmSelected = null;
      this.mtrlItmList.forEach((mtrl) => {
        if(mtrl.mtrlItmId == this.mtrlItmIdInput) {
          mtrlItmSelected = mtrl;
        }
      })

      var workTypeLarge = null;
      this.workTypeList.forEach((workType) => {
        if(workType.workTypeLargeId == this.workTypeLargeIdInput) {
          workTypeLarge = workType;
        }
      });

      var workTypeMed = null;
      workTypeLarge.workTypeMed.forEach((workType) => {
        if(workType.workTypeMedId == this.workTypeMedIdInput) {
          workTypeMed = workType;
        }
      })

      var isNewWorkTypeLg = true;
      this.descListCurrentSeq.forEach((workTypeLg) => {
        if(workTypeLg && workTypeLg.workTypeLargeId == this.workTypeLargeIdInput) {
          isNewWorkTypeLg = false;
          var isNewWorkTypeMd = true;
          workTypeLg.workTypeMd.forEach((workTypeMd) => {
            if(workTypeMd && workTypeMd.workTypeMedId == this.workTypeMedIdInput) {
              workTypeMd.descList.push({
                mtrlItmDescId: null,
                // budgetId: this.budgetId;
                workTypeId: this.workTypeMedIdInput,
                mtrlItm: mtrlItmSelected,
                qntty: 0,
                mtrlCst: 0,
                lbrCst: 0,
                othrCst: 0,
                supplier: null,
                etc: null
              });
              isNewWorkTypeMd = false;
            }
          });

          if(isNewWorkTypeMd) {
            workTypeLg.workTypeMd.push({
              ...workTypeMed,
              descList: [{
                mtrlItmDescId: null,
                // budgetId: this.budgetId;
                workTypeId: this.workTypeMedIdInput,
                mtrlItm: mtrlItmSelected,
                qntty: 0,
                mtrlCst: 0,
                lbrCst: 0,
                othrCst: 0,
                supplier: null,
                etc: null
              }]
            })
          }
        }
      })

      if(isNewWorkTypeLg) {
        this.descListCurrentSeq.push({
          workTypeLargeId: workTypeLarge.workTypeLargeId,
          typeOrder: workTypeLarge.typeOrder,
          typeNm: workTypeLarge.typeNm,
          workTypeMd: [{
            ...workTypeMed,
            descList: [{
              mtrlItmDescId: null,
              // budgetId: this.budgetId;
              workTypeId: this.workTypeMedIdInput,
              mtrlItm: mtrlItmSelected,
              qntty: 0,
              mtrlCst: 0,
              lbrCst: 0,
              othrCst: 0,
              supplier: null,
              etc: null
            }]
          }]
        })

      }
      this.closeModal();
    },

    closeModal() {
			window.$('#addDesc').modal('hide');
		},
  }
}
</script>

