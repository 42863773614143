<template>
    <div class="ca_main">
		<div class="x_div_center">
			<div class="pg_stage">

				<div class="d-flex pt-5">
					<div style="flex:0 0 calc(100% - 100px) !important;"><span class="fa-Kor-Noto" style="font-weight:700; font-size:22pt; color:#333333">비딩 참여 관리</span></div>
					<div class="x_div_center"><div class="text-end" style="width:100px"><span class="fa-Kor-Noto" style="font-size:13pt; color:#484848"><span class="fw-bold"></span></span></div></div>
				</div>

				<div style="height:5px; background-color:#dccccc; border-radius:10rem"></div>

				<div style="height:14px"></div>

				<div class="sw-1">
					<div class="sw-2" style="border-bottom:1px solid #c9c9c9">
						<div class="sw-4">
							<div :class="'x_div_center pg_tab' + (url.indexOf('/partner/bidding-participate') >= 0 ? '_on' : '')" style="height:50px" onclick="location.href='/project/bidding/partner/bidding-participate'"><span class="fkr-set1 fs-095">1. 제안 참여 의향 등록</span></div>
						</div>
						<div class="sw-4">
							<div :class="'x_div_center pg_tab' + (url.indexOf('/partner/bidding-proposal') >= 0 ? '_on' : '')" style="height:50px" onclick="location.href='/project/bidding/partner/bidding-proposal'"><span class="fkr-set1 fs-095">2. 제안 제출 목록 (선정시)</span></div>
						</div>
					</div>
					<div class="sw-2" style="border-bottom:1px solid #c9c9c9">
						<div class="sw-4">
							<div :class="'x_div_center pg_tab' + (url.indexOf('/partner/bidding-result') >= 0 ? '_on' : '')" style="height:50px" onclick="location.href='/project/bidding/partner/bidding-result'"><span class="fkr-set1 fs-095">3. 제안 평가 결과 확인</span></div>
						</div>
						<div class="sw-4">
							<div :class="'x_div_center pg_tab' + (url.indexOf('/partner/contract-list') >= 0 ? '_on' : '')" style="height:50px" onclick="location.href='/project/bidding/partner/contract-list'"><span class="fkr-set1 fs-095">4. 계약 목록 (선정시)</span></div>
						</div>
					</div>
				</div>

				<div class="x_div_center py-5">
					<div class="text-center">
						<span class="fkr-set2 fs-10" v-if="commPolicy == 'agreed'">참여 의향을 등록하실 고객 프로젝트들을 선택하세요.<br><br>향후 고객이 파트너님을 후보업체로 선정하실 경우, <br v-if="isMobile"/>제안 제출 요청이 들어옵니다.</span>
						<span class="fkr-set2 fs-10" v-else>참여 의향을 등록하신 프로젝트 목록입니다.<br><br>신규 프로젝트에 참여 의향을 등록하시고자 할 경우<br>My Projects 페이지에서 <a href="/project/bidding/partner/instruction"><u>프로젝트 찾고 비딩 참여하기</u></a> <br v-if="isMobile"/>메뉴를 이용하세요.</span>
					</div>
				</div>

				<div>
					<div class="pg_m_set" v-if="fetching">
						<div class="x_div_center"><div><i class='h3 fa fa-spinner fa-spin'></i></div></div>
					</div>
					<div v-else class="table-responsive" style="border:1px solid #c9c9c9; border-radius:8px">
						<table class="table-borderless w-100">
							<tr style="height:55px; border-bottom:1px solid #c9c9c9; background-color:#eaeaea">
								<!-- <td class="text-center br_0" width="18%"><span class="ff-kor1" style="font-size:12pt"><strong>고객</strong></span></td> -->
								<td class="text-center br_0" width="16%"><span class="ff-kor1" style="font-size:12pt"><strong>등록<br v-if="isMobile"/>날짜</strong></span></td>
								<td class="text-center br_0" width="16%"><span class="ff-kor1" style="font-size:12pt"><strong>참여 의향 등록<br v-if="isMobile"/>기한</strong></span></td>
								<td class="text-center br_0" width="38%"><span class="ff-kor1" style="font-size:12pt"><strong>건축<br v-if="isMobile"/>위치</strong></span></td>
								<td class="text-center br_0" width="10%"><span class="ff-kor1" style="font-size:12pt"><strong>{{isMobile? '타입' : '건축물 타입'}}</strong></span></td>
								<td class="text-center br_0" width="10%"><span class="ff-kor1" style="font-size:12pt"><strong>상세<br v-if="isMobile"/>정보</strong></span></td>
								<td class="text-center" width="10%"><span class="ff-kor1" style="font-size:12pt"><strong>참여 <br v-if="isMobile"/>의향 <br v-if="isMobile"/>등록</strong></span></td>
							</tr>

							<tr v-for="(prj, i) in prjList" :key="i" style="height:55px; border-bottom:1px solid #c9c9c9">
								<!-- <td class="text-center br_0"><span class="ff-kor1" style="font-size:11pt">{{renderEmailForm(prj.ownrEmail)}}</span></td> -->
								<td class="text-center br_0"><span class="ff-eng1" style="font-size:11pt">{{renderDateForm(prj.bddngCrtDt)}}</span></td>
								<td class="text-center br_0"><span class="ff-eng1" style="font-size:11pt">{{renderDateForm(prj.bddngJoinDdln)}}</span></td>
								<td class="text-center br_0"><a :href="isMobileDevice? prj.maplinkMobile : prj.maplink" target="_blank"><span class="ff-kor1" style="font-size:11pt"><u>{{prj.addr}}</u></span></a></td>
								<td class="text-center br_0" >
									<span class="ff-kor1" style="font-size:11pt">{{isMobile? prj.purpose.replace('용 건물', '') : prj.purpose}} <br> {{ '(' + prj.prcNm + (isMobile? '' : ' 프로젝트') + ')'}}</span>
								</td>
								<td class="text-center br_0"><div class="x_div_center"><button @click="$refs.BiddingPrjDetail.showDetail(prj.prjId, prj.prcCd)" class="x_div_center" :style="'height:30px; border:0; background-color:#eee7e7; border:1px solid #4e0000; border-radius:4px; color:#4e0000; ' + (isMobile? 'width:40px; height:40px;' : 'width:90px; height:30px;')"><span class="fkr-set2">보기</span></button></div></td>
								<td class="text-center"><div class="x_div_center">
									<button v-if="prj.bddngId == 0" @click="$refs.BiddingAttchFlUploaderMulti.showFileUploadForm(prj, false)" class="x_div_center" :style="'height:30px; border:0; background-color:#4e0000; border-radius:4px; ' + (isMobile? 'width:50px; height:40px;' : 'width:90px; height:30px;')"><span class="fkr-set2 fc-w1">등록</span></button>
									<button v-else class="x_div_center" @click="$refs.BiddingAttchFlUploaderMulti.showFileUploadForm(prj, true)" :disabled="prj.isRqstDdlnOver" :style="'border:0; background-color:#fff; border:1px solid #4e0000; border-radius:4px; color:#4e0000; ' + (isMobile? 'width:50px; height:40px;' : 'width:90px; height:30px;') + (prj.isRqstDdlnOver? ' opacity: 0.5' : '')">
										<span class="fkr-set2">수정/취소</span>
									</button>
								</div></td>
							</tr>
						</table>
					</div>

					<!-- 상세정보 레이어 시작 { -->
					<BiddingPrjDetail ref="BiddingPrjDetail" :prcCd="prcCd" :commPolicy="commPolicy"  @prcTree="savePrcTree" @prjList="prjListUpdated"/>

					<!-- 참여의향 등록 레이어 시작 { -->
					<BiddingAttchFlUploaderMulti ref="BiddingAttchFlUploaderMulti" :isMobile="isMobile" :prcCd="prcCd" :step="1" @fileUploaded="attchFlUploaded"/>

				</div>

				<div style="height:50px"></div>

			</div>
		</div>
	</div>
</template>

<script>
import Auth from '@/api/auth'
import BiddingPrjDetail from '@/components/BiddingPrjDetail.vue'
import BiddingAttchFlUploaderMulti from '@/components/BiddingAttchFlUploaderMulti.vue'
const processCode = {
    DS: 'DS',
    MF: 'MA',
    CT: 'CN',
	IN: 'IN',
	DE: 'DE',
	DH: 'DH',
	IS: 'IS'
}

export default {
    name: 'BiddingPartnerParticipate',
	components: {
		BiddingPrjDetail, BiddingAttchFlUploaderMulti
	},
    data() {
        return {
            url : '',
            usrRole : this.user.info.usrRole,
			prcCd : processCode[this.user.info.usrRole],
			commPolicy : ['DS', 'CT'].indexOf(this.user.info.usrRole) >= 0? sessionStorage.getItem('commission_policy') : 'agreed',
            fetching : true,
			isMobile : false,
			prjList : [],
			prcTree : [],
        }
    },
    props: {
        user: {
            type: Object,
            default: null
        },
		isMobileDevice: {
			type: Boolean,
            default: false
		}
    },
    created() {
        this.url = location.href;
    },
    async mounted() {
        if(!await Auth.isLogedIn()){
           this.$router.push({ path: '/member/login', query: { fromBidding: 1 } });
        }
        else if(Auth.partnerRoleTypes.indexOf(this.usrRole) <0) {
            alert('접근권한이 없습니다.');
            location.href = '/project/dashboard';
        }

		this.isMobile = window.innerWidth < 768;
        await this.$refs.BiddingPrjDetail.fetchBiddableProjects();
		this.fetching = false;
    },
    methods: {
		savePrcTree(prcTree) {
			this.prcTree = prcTree;
		},
		prjListUpdated(prjList) {
			var prcInfoList = this.prcTree.processList.concat(this.prcTree.prcScndryList);
			this.prjList = prjList;
			this.prjList.forEach((prj) => {
				prcInfoList.forEach((prcInfo) => {
					if(prj.prcCd == prcInfo.code) {
						prj.prcNm = prcInfo.name;
						return;
					}
				})
			})
		},
		attchFlUploaded() {
			this.fetching = true;
			this.$refs.BiddingPrjDetail.fetchBiddableProjects();
			this.fetching = false;
		},
		renderDateForm(datetime) {
            if (this.isMobile) {
                return datetime? datetime.split('T')[0].substring(5) : '';
            }
            else {
                return datetime? datetime.split('T')[0] : '';
            }
        },
		renderEmailForm(email) {
            if (this.isMobile) {
                return email? (email.split('@')[0] + '@\n' + email.split('@')[1]) : '';
            }
            else {
                return email;
            }
        }
    }
}
</script>